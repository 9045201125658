import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, Input as InputText, InputLabel } from '@material-ui/core';

import InputMask from 'react-input-mask';

const Input = props => {
  const { label, mask, message = '', error = '', value, onChange, ...rest } = props;

  const [id] = useState('input-id-' + Input.COUNT++);

  return (
    <FormControl className='w-100' error={!!error}>
      <InputLabel id={id + '-label'} htmlFor={id}>
        {label}
      </InputLabel>

      {mask ? (
        <InputMask mask={mask} maskChar={''} {...rest}>
          {props => {
            return <InputText id={id} {...props} />;
          }}
        </InputMask>
      ) : (
        <InputText {...rest} value={value} onChange={onChange} />
      )}

      <FormHelperText id={id + '-text'}>{error || message}</FormHelperText>
    </FormControl>
  );
};

Input.COUNT = 0;

Input.propTypes = {
  label: PropTypes.string,
  mask: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default Input;
