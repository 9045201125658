import React, {useEffect, ReactNode} from "react";
import {Redirect} from "react-router-dom";

import {useAuth} from "src/hooks";
import {PleaseWaitForApprove} from "src/views/blocks";
import {ROUTES} from "src/router";
import store from "src/store";


type Props = {
    children?:ReactNode;
};

const ProtectedLayout:React.FC<Props> = (props:Props) => {
    const {
        children
    } = props;

    const auth = useAuth();

    useEffect(() => {
        store.auth.getUserRoles();
    }, []);

    return (
        <React.Fragment>
            {!auth.isLoggedIn && (
                <Redirect to={ROUTES.login} />
            )}

            {auth.isLoggedIn && !auth.isAdmin && (
                <React.Fragment>
                    {!auth.isAdmin && (
                        <PleaseWaitForApprove />
                    )}
                </React.Fragment>
            )}

            {auth.isLoggedIn && auth.isAdmin && (
                children
            )}
        </React.Fragment>
    );
};


export default ProtectedLayout;