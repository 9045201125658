import React, {
    useState,
    useCallback,
    ChangeEvent
} from "react";
import PropTypes from "prop-types";
import {
    FormControl,
    InputLabel,
    Select as UISelect,
    MenuItem,
    FormHelperText
} from "@material-ui/core";
import {Value} from '../types';

type Props = {
    options?: {
        title?: string;
        value: Value;
    }[];
    label?: string;
    message?: string;
    error?: string;
    disabled?: boolean;
    value?: Value;
    onChange?: (value: Value) => void;

};

const Select:React.FC<Props> & {COUNT: number} = (props:Props) => {
    const {
        label,
        options = [],
        message,
        error,
        disabled,
        onChange,
        ...rest
    } = props;

    const [id] = useState("select-id-" + Select.COUNT++);

    const handleChange = useCallback((e:any) => {
        if(onChange) {
            const {
                target: {
                    value
                }
            } = e;

            onChange(value);
        }
    }, []);

    return (
        <FormControl
          className="w-100"
          error={Boolean(error)}>
            <InputLabel
              id={id + "-label"}
              htmlFor={id}>
                {label}
            </InputLabel>

            <UISelect
              {...rest}
              id={id}
              disabled={disabled}
              labelId={id + "-label"}
              onChange={handleChange}>
                {
                    options.map((option, index) => {
                        return (
                            <MenuItem key={index}
                              value={option.value}>
                                {option.title}
                            </MenuItem>
                        );
                    })
                }
            </UISelect>

            <FormHelperText
              id={id + "-text"}>
                {error || message}
            </FormHelperText>
        </FormControl>
    );
};

Select.COUNT = 0;
Select.propTypes = {
    label: PropTypes.string,
    message: PropTypes.string,
    error: PropTypes.string,
    options: PropTypes.array.isRequired
};


export default Select;
