import React from "react";
import PropTypes from "prop-types";
import {
    Divider,
    Grid
} from "@material-ui/core";
import {Check as CheckIcon} from "@material-ui/icons";

import {
    ActionDelete,
    EntityList
} from "src/views/core";

import {
    TileParameter,
    ITileParameter
} from "src/store/models/TileParameter";


const List = (props:any) => {
    const {
        rowActions
    } = props;

    return (
        <EntityList {...props}
          titleHeading="Ratings"
          title="Tile parameter list"
          breadcrumbs={[
            {
                title: "Tile parameter",
                to: `/${TileParameter.modelName}`
            }
          ]}
          headers={[
            {
                name: "icon",
                title: "Icon",
                width: 100,
                render(record:ITileParameter) {
                  const {
                    icon,
                    label = ""
                  } = record;

                  return icon && (
                      <Grid container
                        style={{background: "#969696", padding: 7}}
                        justifyContent="center">
                          <img
                            style={{
                              maxWidth: 55,
                              maxHeight: 55
                            }}
                            alt={label}
                            src={icon} />
                      </Grid>
                  );
                }
            },
            {
                name: "id",
                title: "ID",
                sortable: true,
                width: 350
            },
            {
                name: "is_suggested",
                title: "Is suggested",
                width: 150,
                render(record:ITileParameter) {
                    return (
                        <React.Fragment>
                            {record.isSuggested && (
                                <CheckIcon />
                            )}
                        </React.Fragment>
                    );
                }
            },
            {
                name: "label",
                title: "Label",
                sortable: true
            }
          ]}
          rowActions={[
            ...rowActions,
            <Divider />,
            <ActionDelete />
          ]} />
    );
};

List.propTypes = {
    name: PropTypes.string
};


export default List;