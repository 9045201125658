import React from "react";
import {
    TableCell,
    TableSortLabel
} from "@material-ui/core";


const GridTableHead = (props) => {
    const {
        header,
        order,
        orderBy,
        onClickSort
    } = props;

    const {
        name,
        title,
        sortable,
        align = "left",
        width
    } = header || {};

    const handleSort = () => {
        if(onClickSort) {
            onClickSort(name);
        }
    };

    return (
        <TableCell
          padding="normal"
          align={align}
          width={width}
          sortDirection={orderBy === name ? order : false}>
            {
                sortable ? (
                    <TableSortLabel
                      active={orderBy === name}
                      direction={orderBy === name ? order : "asc"}
                      onClick={() => handleSort()}>
                        <span>
                        {title || name}
                        </span>
                    </TableSortLabel>
                ) : (
                    <span>
                        {title || name}
                    </span>
                )
            }
        </TableCell>
    );
};


export default GridTableHead;