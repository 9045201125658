import React from "react";
import {compose} from "redux";
import {
    WrappedFieldProps
} from "redux-form";
import {
    createStyles,
    withStyles,
    WithStyles,
    Theme,
    TextField,
    Typography
} from "@material-ui/core";


interface Props {
    required?:boolean;
    label?:string;
}

interface CProps extends Props, WithStyles, WrappedFieldProps {}

class InputWrapper extends React.Component<CProps, any> {
    render() {
        const {
            classes,
            meta,
            input,
            required = false,
            label,
            ...rest
        } = this.props;

        const labelNode = (
            <span>
                {label}
                <Typography key={2} color="error" display="inline">
                    {required ? "*" : ""}
                </Typography>
            </span>
        );

        return (
            <TextField
              {...rest}
              className={classes.input}
              label={labelNode}
              name={input.name}
              value={input.value}
              helperText={meta.touched && meta.error}
              error={meta.touched && meta.invalid}
              onChange={input.onChange}
              onFocus={input.onFocus}
              onBlur={input.onBlur} />
        );
    }
}


const styles = (theme:Theme) => createStyles({
    input: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
});

export default compose<React.ComponentType<Props>>(
    withStyles(styles)
)(InputWrapper);