import {createStore} from "kpdux";
import {reducer as formReducer} from "redux-form";

import {apiFactory, ApiFactoryState} from "./modules/api.factory";
import {app, AppState} from "./modules/app.module";
import {auth, AuthState} from "./modules/auth.module";
import {ormFactory, ORMFactoryState} from "./modules/orm.factory";
import dataModule from "./modules/data.module";
import {storage} from "./modules/storage.module";
import {ui, UIState} from "./modules/ui.module";

import Area from "./models/Area";
import CityCenter from "./models/CityCenter";
import {DemandCategory} from "./models/DemandCategory";
import DemandVote from "./models/DemandVote";
import {RawData} from "./models/RawData";
import Suggest from "./models/Suggest";
import SuggestAnalytic from "./models/SuggestAnalytic";
import {SuggestCategory} from "./models/SuggestCategory";
import SuggestComment from "./models/SuggestComment";
import Tile from "./models/Tile";
import TileAnalytic from "./models/TileAnalytic";
import {TileParameter} from "./models/TileParameter";
import {User} from "./models/User";

import {API_URL} from "src/env";


type State = {
    api:ApiFactoryState;
    app:AppState,
    auth:AuthState;
    orm:ORMFactoryState;
    ui:UIState;
};

const store = createStore({
    api: apiFactory(API_URL),
    app,
    auth,
    orm: ormFactory({
        models: [
            Area,
            CityCenter,
            DemandCategory,
            DemandVote,
            RawData,
            Tile,
            TileAnalytic,
            TileParameter,
            Suggest,
            SuggestAnalytic,
            SuggestCategory,
            SuggestComment,
            User
        ]
    }),
    data: dataModule,
    storage,
    ui
}, {
    form: formReducer
});


export type {State};

export * from "./models/RawData";

export default store;