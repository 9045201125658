import React, {useEffect, Suspense} from "react";
import {Switch, Route} from "react-router-dom";

import {
    useAuth,
    useDebug
} from "src/hooks";

import {Loader} from "src/views/blocks";
import {
    AuthLogin,
    Logout,
    Error404
} from "src/views/pages";
import {
    DashboardLayout,
    DefaultLayout,
    ProtectedLayout
} from "src/views/layouts";
import {Resource} from "src/views/core";

import {rawDataProps} from "src/views/resources";
import * as resources from "src/views/resources";

import {ROUTES} from "src/router";
import store from "src/store";

import "./App.scss";


const App = ():JSX.Element => {
    const auth = useAuth();

    useDebug();

    const resourcesPaths = Object.values(resources).map((resource) => {
        return resource.basePath || `/${resource.name}`;
    });

    useEffect(() => {
        const delay = Math.max(0, auth.expires - 30 * 1000 - new Date().getTime());

        const timeout = setTimeout(() => {
            store.auth.refresh();
        }, delay);

        return () => clearTimeout(timeout);
    }, [auth.token]);

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route path={ROUTES.login} exact>
                    <DefaultLayout>
                        <AuthLogin />
                    </DefaultLayout>
                </Route>

                <Route exact path={ROUTES.logout}>
                    <DefaultLayout>
                        <Logout />
                    </DefaultLayout>
                </Route>

                <Route exact path={ROUTES.home}>
                    <DashboardLayout>
                        <ProtectedLayout>
                            <Resource {...rawDataProps}
                              basePath="/" />
                        </ProtectedLayout>
                    </DashboardLayout>
                </Route>

                <Route path={resourcesPaths}>
                    <DashboardLayout>
                        <ProtectedLayout>
                            {Object.values(resources).map((resource) => {
                                return (
                                    <Resource key={resource.name}
                                      {...resource} />
                                );
                            })}
                        </ProtectedLayout>
                    </DashboardLayout>
                </Route>

                <Route>
                    <Error404 />
                </Route>
            </Switch>
        </Suspense>
    );
};


export default App;