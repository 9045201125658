import React from "react";
import {
    SvgIcon,
    SvgIconProps
} from "@material-ui/core";


const NeutralEmotion:React.FC<SvgIconProps> = (props:SvgIconProps) => {
    const {
        viewBox,
        ...rest
    } = props;

    return (
        <SvgIcon
          {...rest}
          viewBox="0 0 50 50">
            <path
              d="M27,2A25,25,0,1,0,52,27,25,25,0,0,0,27,2M14.5,20.75a3.75,3.75,0,1,1,3.75,3.75,3.75,3.75,0,0,1-3.75-3.75M37,37H17V32H37v5M35.75,24.5a3.75,3.75,0,1,1,3.75-3.75,3.75,3.75,0,0,1-3.75,3.75Z"
              transform="translate(-2 -2)" />
        </SvgIcon>
    );
};


export default NeutralEmotion;