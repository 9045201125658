import {IModule} from "kpdux";
import * as fns from "date-fns";

import {parseJWT} from "src/tools";

import {
    PASSWORD_ID,
    PASSWORD_SECRET
} from "src/env";


type State = {
    token:string|null;
    refreshToken:string|null;
    expires?:number;
    headers?:{
        Authorization:string;
    };
    isRefreshingToken:boolean;
    isVerified:boolean|null;
    roles:any[];
};

const auth:IModule<State> = {
    state: (() => {
        const state:State = {
            token: null,
            refreshToken: null,
            expires: 0,
            isRefreshingToken: false,
            isVerified: null,
            roles: []
        };

        try {
            state.token = localStorage.getItem("auth:token") || null;
            state.refreshToken = localStorage.getItem("auth:refreshToken") || null;
        }
        catch(ignore) {}

        return state;
    })(),
    getters: {
        token(state:State):State["token"] {
            return state.token;
        },
        refreshToken(state:State):State["refreshToken"] {
            return state.refreshToken;
        },
        tokenData(state:State):any {
            const {token} = state;

            return token ? parseJWT(token) : {};
        },
        expires(state:State, getters):number {
            const {
                exp = 0
            } = getters.tokenData;

            return exp * 1000;
        },
        isExpired(state:State, getters):boolean {
            return fns.isBefore(new Date(getters.expires), new Date());
        },
        isLoggedIn(state:State, getters):boolean {
            return Boolean(getters.token && !getters.isExpired);
        },
        isAdmin(state:State) {
            const {
                roles
            } = state;

            if(roles && Array.isArray(roles)) {
                return !!(roles).find((role) => {
                    return role.name === "admin";
                });
            }

            return false;
        },
        isVerified(state:State) {
            return state.isVerified;
        },
        isRefreshingToken(state:State) {
            return state.isRefreshingToken;
        },
        authHeaders(state:State) {
            return {
                Authorization: `Bearer ${state.token}`
            };
        }
    },
    actions: {
        async login(data) {
            const res = await this.dispatch("api/post", "/api/oauth/token", {
                data: {
                    ...data,
                    grant_type: "password",
                    client_id: process.env.REACT_APP_PASSWORD_ID,
                    client_secret: process.env.REACT_APP_PASSWORD_SECRET,
                    scope: "*"
                }
            });

            if(res && res.access_token) {
                const {
                    access_token: token,
                    refresh_token: refreshToken
                } = res;

                this.setToken(token);
                this.setRefreshToken(refreshToken);

                await this.getUserRoles();

                return {
                    status: "OK"
                };
            }
            else if(res) {
                const {
                    response: {
                        message = "",
                        validationMessages: errors = {}
                    } = {}
                } = res;

                return {
                    status: "ERROR",
                    message: message,
                    errors: errors
                };
            }

            return {
                status: "ERROR",
                message: "Server connection error"
            };
        },
        async logout() {
            this.setToken(null);
            this.setRefreshToken(null);
            this.updateUserRoles([]);
        },
        async refresh() {
            const res = await this.dispatch("api/post", "/api/oauth/token", {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                },
                data: {
                    grant_type: "refresh_token",
                    client_id: PASSWORD_ID,
                    client_secret: PASSWORD_SECRET,
                    scope: "*",
                    refresh_token: this.getters.refreshToken
                }
            });

            if(res && res.access_token) {
                const {
                    access_token: token,
                    refresh_token: refreshToken,
                } = res;

                this.setToken(token);
                this.setRefreshToken(refreshToken);

                return {
                    status: "OK"
                };
            }

            return {
                status: "ERROR"
            };
        },
        async getUserRoles() {
            try {
                const res = await this.dispatch("api/get", "/api/user-roles", {
                    headers: this.getters.authHeaders
                });

                if(res.httpCode === 200) {
                    const {
                        response: roles = []
                    } = res;

                    this.updateUserRoles(roles);

                    return {
                        status: "OK",
                        items: roles
                    };
                }

                return {
                    status: "ERROR",
                    message: "Error message"
                };
            }
            catch(err:any) {
                return {
                    status: "ERROR",
                    message: err.message
                };
            }
        }
    },
    mutations: {
        setToken(state:State, token:State["token"]) {
            if(token) {
                localStorage.setItem("auth:token", token);
            }
            else {
                localStorage.removeItem("auth:token");
            }

            state.token = token;
        },
        setRefreshToken(state:State, refreshToken:State["refreshToken"]) {
            if(refreshToken) {
                localStorage.setItem("auth:refreshToken", refreshToken);
            }
            else {
                localStorage.removeItem("auth:refreshToken");
            }

            state.refreshToken = refreshToken;
        },
        setIsRefreshingToken(state:State, isRefreshing:boolean) {
            state.isRefreshingToken = isRefreshing;
        },
        updateUserRoles(state:State, roles:any[]) {
            state.roles = roles;
        },
        updateIsVerified(state:State, isVerified:boolean) {
            state.isVerified = isVerified;
        }
    }
};


export type {State as AuthState};

export {auth};