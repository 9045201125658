import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {ListItem} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {useShowMessages } from "src/hooks";
import {DialogDelete} from "src/views/blocks";
import {useGridTableRowContext} from "src/views/blocks/GridTable/context";
import {useListContext} from "../EntityList/context";

import store from "src/store";


const ActionDelete = (props) => {
    const {description} = props;

    const {record} = useGridTableRowContext();
    const listContext = useListContext();
    const showMessages = useShowMessages();

    const deleteHandle = async () => {
        const res = await props.remove(listContext.name, record.id);

        showMessages(res);

        await listContext.reload();
    };

    return (
        <DialogDelete
          description={typeof description === "function" ? description(record) : description}
          activator={(props) => {
            return (
                <ListItem {...props} button>
                    <div className="nav-link-icon opacity-6">
                        <FontAwesomeIcon
                          className="text-danger"
                          icon={["fas", "times"]} />
                    </div>

                    <span className="text-danger ml-4">Delete</span>
                </ListItem>
            );
          }}
          onDelete={deleteHandle} />
    );
};

ActionDelete.propTypes = {
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ])
};


const putState = () => {
  return {};
};

const putActions = () => {
    return {
        remove: store.orm.remove
    };
};

export default connect(putState, putActions)(ActionDelete);