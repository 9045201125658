import React, {FC, useEffect} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

import {ROUTES} from "src/router";
import store from "src/store";


type RActions = {
    logout:() => Promise<void>;
};

type Props = RActions;

const Component:FC<Props> = (props:Props) => {
    const {logout} = props;
    const history = useHistory();

    useEffect(() => {
        logout().then(() => history.push(ROUTES.login));
    }, [history, logout]);

    return (
        <div />
    );
};

const putActions = ():RActions => {
    return {
        logout: store.auth.logout
    };
};

export const Logout = connect(null, putActions)(Component);