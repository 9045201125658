import React from "react";
import {
    SvgIcon,
    SvgIconProps
} from "@material-ui/core";


const HappyEmotion:React.FC<SvgIconProps> = (props:SvgIconProps) => {
    const {
        viewBox,
        ...rest
    } = props;

    return (
        <SvgIcon
          {...rest}
          viewBox="0 0 100 100">
            <path
              d="M52,2a50,50,0,1,0,50,50A50,50,0,0,0,52,2M27,39.5A7.69,7.69,0,0,1,34.5,32a7.5,7.5,0,1,1,0,15A7.69,7.69,0,0,1,27,39.5M52,78.15c-8.75,0-16.45-3.65-20.95-9.05l7.1-7.1c2.25,3.6,7.6,6.15,13.85,6.15S63.6,65.6,65.85,62l7.1,7.1c-4.5,5.4-12.2,9.05-20.95,9.05M69.5,47A7.5,7.5,0,1,1,77,39.5,7.69,7.69,0,0,1,69.5,47Z"
              transform="translate(-2 -2)" />
        </SvgIcon>
    );
};


export default HappyEmotion;