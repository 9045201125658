import React, {
    useState,
    useEffect
} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    formValueSelector
} from "redux-form";
import * as lodash from "lodash";
import {
    Grid
} from "@material-ui/core";

import {
    Input,
    InputAuto,
    InputHidden
} from "../../rf-wrappers";

import {useShowMessages} from "../../../hooks";
import store from "../../../store";


const AddressWrapper = (props) => {
    const {
        mapNames,
        values
    } = props;

    const {
        country: countryCode = ""
    } = values;

    const [mount] = useState(true);
    const [countryLoading, setCountryLoading] = useState(true);
    const [regionLoading, setRegionLoading] = useState(false);
    const showMessages = useShowMessages();

    const fieldsProps = Object.keys(mapNames).reduce((fieldsProps, name) => {
        fieldsProps[name] = lodash.get(props, mapNames[name]);

        return fieldsProps;
    }, {});

    const countries = props.find("nomenclature", {
        code: "country"
    });
    const country = countries.find((country) => {
        return country.code === (countryCode || "").toLowerCase();
    });

    const regions = props.find("nomenclature", {
        parentId: country && country.id,
        code: "county"
    });

    useEffect(() => {
        props.search("nomenclature", {
            code: "country"
        }).then((res) => {
            if(res.status === "OK") {
                setCountryLoading(false);
            }
            else {
                showMessages(res);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mount]);

    useEffect(() => {
        if(country) {
            setRegionLoading(true);

            props.search("nomenclature", {
                parentId: country && country.id,
                code: "county"
            }).then((res) => {
                if(res.status === "OK") {
                    setRegionLoading(false);
                }
                else {
                    showMessages(res);
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={6} sm={3} md={3}>
                <InputAuto
                  {...fieldsProps.country}
                  raw
                  label="Country"
                  loading={countryLoading}
                  options={countries.map((country) => {
                    return {
                        title: country.label,
                        value: country.code
                    };
                  })} />
            </Grid>

            <Grid item xs={6} sm={3} md={3}>
                <InputAuto
                  {...fieldsProps.region}
                  raw
                  label="Region"
                  loading={regionLoading}
                  options={regions.map((region) => {
                    return {
                        title: region.label,
                        value: region.code
                    };
                  })} />
            </Grid>

            <Grid item xs={6} sm={3} md={3}>
                <Input
                  {...fieldsProps.locality}
                  label="Locality" />
            </Grid>

            <Grid item xs={6} sm={3} md={3}>
                <Input
                  {...fieldsProps.postal_code}
                  mask="999999"
                  label="Postal code" />
            </Grid>

            <Grid item xs={8} md={9} xl={7}>
                <Input
                  {...fieldsProps.street}
                  label="Street" />
            </Grid>

            <Grid item xs={4} md={3} xl={1}>
                <Input
                  {...fieldsProps.street_number}
                  label="Street number" />
            </Grid>

            <Grid item xs={6} md={3} xl={1}>
                <Input
                  {...fieldsProps.building}
                  label="Building" />
            </Grid>

            <Grid item xs={6} md={3} xl={1}>
                <Input
                  {...fieldsProps.building_number}
                  label="Building number" />
            </Grid>

            <Grid item xs={6} sm={3} xl={1}>
                <Input
                  {...fieldsProps.staircase}
                  label="Staircase" />
            </Grid>

            <Grid item xs={6} sm={3} xl={1}>
                <Input
                  {...fieldsProps.floor}
                  label="Floor" />
            </Grid>

            <InputHidden
              {...fieldsProps.latitude} />

            <InputHidden
              {...fieldsProps.longitude} />
        </Grid>
    );
};

AddressWrapper.propTypes = {
    onChange: PropTypes.func
};


const putState = (state, props) => {
    return {
        find: store.orm.getters.find,
        values: {
            ...Object.keys(props.mapNames).reduce((values, name) => {
                const path = props.mapNames[name];
                const fieldProps = lodash.get(props, path);

                const selector = formValueSelector(fieldProps.meta.form);

                values[name] = selector(state, path);

                return values;
            }, {})
        }
    };
};

const putActions = () => {
    return {
        fetch: store.orm.fetch,
        search: store.orm.search
    };
};

export default connect(putState, putActions)(AddressWrapper);