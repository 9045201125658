import {Model} from "src/makes/Model";
import {calcRate} from "src/tools";


export interface ITile {
    id:string;
    quadKey:string;
    // address:string;
    // percentile:0;
    // votesGeneral:0;
    // votesLocalNegative:0;
    // votesLocalNeutral:0;
    // votesLocalPositive:0;
    votesNegative:number;
    votesNeutral:number;
    votesPositive:number;
    // votesSurroundingGeneral:1;
    // votesSurroundingNegative:0;
    // votesSurroundingNeutral:1;
    // votesSurroundingPositive:0;
    // votesSurroundingTotal:1;
    // votesTotal:1;
    commentsTotal:number;
    commentsGeneral:number;
    commentsNegative:number;
    commentsNeutral:number;
    commentsPositive:number;
    rateSurrounding:number;
    voteParameters:{
        [parameterId:string]:{
            positive:number;
            neutral:number;
            negative:number;
            localPositive:number;
            localNeutral:number;
            localNegative:number;
            surroundingTotal:number;
            surroundingNegative:number;
            surroundingNeutral:number;
            surroundingPositive:number;
        }
    }
}

export interface ITileSummary {
  //
}

export class Tile extends Model<ITile> {
    static modelName:string = "tile";
    static queryCount:string = "count";
    static queryPage:string = "page";
    static filters = {
        quadKey: (tile:ITile, quadKey:string|string[]) => {
            if(Array.isArray(quadKey)) {
                return quadKey.reduce((includes:boolean, quadKey:string) => {
                    return includes || tile.quadKey.indexOf(quadKey) === 0;
                }, false);
            }
            else {
                return tile.quadKey.indexOf(quadKey) === 0;
            }
        }
    };

    toString():string {
        return "";
    }

    static prepare(data:any) {
        const {
            percentile,
            ...otherData
        } = data;

        return {
            ...otherData,
            rateSurrounding: percentile
        };
    }

    static listSummary(tiles:ITile[], res:any, query:any):ITileSummary {
        if(query && query.withSummary) {
            const {
                summary: {
                    address = "",
                    parameters = {},
                    ...summary
                } = {}
            } = res || {};

            const {
                votesPositive = 0,
                votesNeutral = 0,
                votesNegative = 0
            } = summary;

            let groups:any = Tile.getTotalsBy("voteGroups", tiles);
            let genders:any = Tile.getTotalsBy("voteGenders", tiles);
            let transports:any = Tile.getTotalsBy("voteTransports", tiles);

            const getMaxTotal = (totals:any[]):any[] => {
                return totals
                  .sort((a, b) => a.rate < b.rate ? 1 : -1)
                  .filter((t, index) => index === 0);
            };

            const rateSurrounding = tiles.length > 0 ? tiles.reduce((sum:number, tile:ITile) => {
                return sum + tile.rateSurrounding;
            }, 0) / tiles.length : 0;

            return {
                rate: calcRate(votesPositive, votesNeutral, votesNegative),
                rateSurrounding: Math.round(rateSurrounding),
                address: address || "No address",
                parameters: Object.keys(parameters).map((parameterId:string) => {
                    const {
                        votes = 0,
                        rate
                    } = parameters[parameterId] || {};

                    const {
                        positive,
                        neutral,
                        negative
                    } = tiles.reduce((summary, tile:ITile) => {

                    const {
                        voteParameters: {
                            [parameterId]: {
                                positive = 0,
                                neutral = 0,
                                negative = 0
                            } = {}
                        } = {}
                    } = tile;

                    summary.positive += positive;
                    summary.neutral += neutral;
                    summary.negative += negative;

                    return summary;
                }, {
                    positive: 0,
                    negative: 0,
                    neutral: 0
                });

                return {
                    parameterId: parameterId,
                    votes: votes,
                    rate: rate || calcRate(positive, neutral, negative),
                    positive,
                    neutral,
                    negative
                };
            }),
            relative: [
                ...getMaxTotal(genders),
                ...getMaxTotal(groups),
                ...getMaxTotal(transports)
            ].map((total:any) => {
                const {
                    positive,
                    neutral,
                    negative
                } = total;

                let rate = calcRate(votesPositive, votesNeutral, votesNegative, false);
                let totalRate = calcRate(positive, neutral, negative, false);

                return {
                    ...total,
                    rate: rate > 0 ? Math.round(((totalRate - rate) / rate) * 100) : 0
                };
            }),
            ...tiles.reduce((summary, tile:ITile) => {
                summary.commentsGeneral += tile.commentsGeneral;
                summary.commentsPositive += tile.commentsPositive;
                summary.commentsNegative += tile.commentsNegative;

                return summary;
            }, {
                commentsGeneral: 0,
                commentsPositive: 0,
                commentsNegative: 0
            }),
            ...summary
        };
        }

        return {};
    }

    static getTotalsBy(by:string, tiles:ITile[]) {
        let totals:{[key:string]:any} = {};

        for (let i in tiles) {
            let tile:ITile = tiles[i];

            for (let key in (tile as any)[by]) {
                let {
                    negative,
                    neutral,
                    positive
                } = (tile as any)[by][key];

                if (!totals[key]) {
                    totals[key] = {
                        key: key,
                        rate: 0,
                        vote: 0,
                        negative: 0,
                        positive: 0,
                        neutral: 0
                    };
                }

                totals[key].positive += positive;
                totals[key].neutral += neutral;
                totals[key].negative += negative;
            }
        }

        return Object.values(totals).map((total:any) => {
            const {
                positive,
                neutral,
                negative
            } = total;

            return {
                ...total,
                vote: positive + neutral + negative,
                rate: calcRate(positive, neutral, negative)
            };
        });
    }
}


export default Tile;