import {ThumbUp as ThumbUpIcon} from "@material-ui/icons";

import {
    EntityCreate,
    ResourceProps,
} from "src/views/core";

import List from "./List";
import Edit from "../../core/Edit";
import Form from "./Form";

import {DemandCategory} from "src/store/models/DemandCategory";


export const demandCategoryProps:ResourceProps = {
    name: DemandCategory.modelName,
    title: "Demand",
    // @ts-ignore
    icon: ThumbUpIcon,
    list: List,
    form: Form,
    edit: Edit,
    create: EntityCreate
};
