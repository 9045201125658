import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";

import {useShowMessages} from "src/hooks";

import {PageTitle} from "src/views/blocks";

import store from "src/store";
import {STATUS_SUCCESS} from "src/makes/Model"


const EntityCreate = (props) => {
    const {
        name,
        form: Form,
        redirectTo,
        initialValues,
        showTitle = true,
        titleHeading,
        titleDescription,
        breadcrumbs
    } = props;

    const history = useHistory();
    const showMessages = useShowMessages();

    const submitHandle = async data => {
        const res = await store.orm.create(name, data);

        showMessages(res);

        if(res.status === STATUS_SUCCESS) {
            console.log('redirectTo', redirectTo);

            if(redirectTo) {
                history.replace(`/${name}`);
            }
        }
    };

    return (
        <Fragment>
            {showTitle && (
              <PageTitle
                titleHeading={titleHeading}
                titleDescription={titleDescription}
                breadcrumbs={breadcrumbs} />
            )}

          <Form
            name={name}
            initialValues={initialValues}
            onSubmit={submitHandle} />
        </Fragment>
    );
};

EntityCreate.propTypes = {
    name: PropTypes.string.isRequired,
    form: PropTypes.any.isRequired,
    initialValues: PropTypes.object,
    redirectTo: PropTypes.string,
    showTitle: PropTypes.bool,
    titleHeading: PropTypes.string,
    titleDescription: PropTypes.string,
    breadcrumbs: PropTypes.array
};


export default EntityCreate;
