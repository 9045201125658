import React, {
    useState,
    useEffect,
    Fragment
} from "react";
import PropTypes from "prop-types";
import * as lodash from "lodash";
import {
    Field
} from "redux-form";
import {
    FormControl,
    Input,
    InputLabel,
    FormHelperText,
    CircularProgress
} from "@material-ui/core";
import {
    Autocomplete
} from "@material-ui/lab";


export const AutoField = (props) => {
    const {
        meta,
        input,
        loading,
        groupBy,
        options,
        label
    } = props;

    const [id] = useState("auto-field-id-" + AutoField.COUNT++);
    const [showOptions, setShowOptions] = useState(options);

    const error = meta.touched ? meta.error : "";

    useEffect(() => {
        if(!lodash.isEqual(options, showOptions)) {
            setShowOptions(options);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[options]);

    const getOptionLabel = (optionOrValue) => {
        switch(typeof optionOrValue) {
            case "string":
            case "number":
            case "boolean": {
                let option = options.find((option) => {
                    return option.value === optionOrValue;
                });

                if (option) {
                    return option.title;
                }

                return "";
            }

            case "object":
                return optionOrValue.title;

            default:
                return "";
        }
    };

    const getOptionSelected = (option, value) => {
        return option.value === value;
    };

    return (
        <FormControl
          className="w-100"
          error={Boolean(error)}>
            <Autocomplete
              id={id}
              {...input}
              groupBy={groupBy}
              options={showOptions}
              getOptionLabel={getOptionLabel}
              getOptionSelected={getOptionSelected}
              renderInput={(props) => {
                const {
                    id,
                    disabled,
                    InputProps: {
                        className: ignore1,
                        ...InputProps
                    },
                    inputProps: {
                        className: ignore2,
                        ...inputProps
                    },
                    InputLabelProps
                } = props;

                return (
                    <Fragment>
                        <InputLabel
                          id={id + "-label"}
                          htmlFor={id}
                          {...InputLabelProps}>
                            {label}
                        </InputLabel>

                        <Input
                          id={id}
                          className="w-100"
                          disabled={disabled}
                          inputProps={inputProps}
                          // name={name}
                          {...InputProps}
                          endAdornment={
                            <Fragment>
                                {
                                    loading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20} />
                                    ) : null
                                }

                                {InputProps.endAdornment}
                            </Fragment>
                          } />

                        <FormHelperText
                          id={id + "-text"}>
                            {error}
                        </FormHelperText>
                    </Fragment>
                );
              }}
              onChange={(e, option) => {
                if(option && option.value) {
                    input.onChange(option.value);
                }
                else {
                    input.onChange(undefined);
                }
              }}
              onFocus={() => {
                input.onFocus();
              }}
              onBlur={() => {
                input.onBlur();
              }} />
        </FormControl>
    );
};

AutoField.COUNT = 0;

AutoField.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    groupBy: PropTypes.func,
    options: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    label: PropTypes.string
};


export const AutoFieldWrapper = (props) => {
    return (
        <Field component={AutoField}
          {...props} />
    );
};

AutoFieldWrapper.propTypes = {
    // raw: PropTypes.bool,
    groupBy: PropTypes.func,
    options: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired
};

export default AutoFieldWrapper;