import {
    createTheme,
    Theme
} from "@material-ui/core";

import "./fontawesome";
import "./index.scss";


const theme:Theme = createTheme({
    spacing: 7,
    palette: {
        primary: {
            main: '#41617F',
            light: '#75AFE5',
            dark: '#1C2326', //,
            // contrastText: ""
        },
        secondary: {
            light: '#B2C8DC',
            main: '#75AFE5',
            dark: '#41617F',
            contrastText: '#FFFFFF',
        },
        text: {
            primary: '#000000',
            secondary: '#A2A2A2'
        },
        success: {
            light: '#CBDAC4',
            main: '#AECB9F',
            dark: '#569E32'
        },
        info: {
            light: '#FCE518',
            main: '#FCE518',
            dark: '#FCE518'
        },
        error: {
            light: '#E8BFC1',
            main: '#E7969A',
            dark: '#E51A24'
        },
        action: {
            selected: '#DCE5EC',
            hover: '#DCE5EC',
            focus: '#DCE5EC'
        }
    },
    typography: {
        fontFamily: "'Open Sans'",
        h1: {
            fontSize: '40px',
            fontWeight: 'bold',
        },
        h2: {
            fontSize: '26px',
            fontWeight: 'bold'
        },
        h3: {
            fontSize: '34px',
            fontWeight: 'bold',
        },
        h4: {
            fontSize: '20px',
            letterSpacing: '0.4px',
            fontWeight: 600
        },
        h5: {
            fontSize: '24px',
            fontWeight: 'bold'
        },
        h6: {
            fontSize: '17px',
            fontWeight: 'bold',
            letterSpacing: '0.83px'
        },
        subtitle1: {
            // fontSize: "17px",
            // color: "#707070",
            // lineHeight: 1
        },
        subtitle2: {
            fontSize: '12px'
        },
        body1: {
            fontSize: '15px',
            lineHeight: 1.3
        },
        body2: {
            fontSize: '14px',
            letterSpacing: '0.046rem'
        },
        caption: {
            fontSize: '10px',
            fontWeight: 'bold',
            letterSpacing: '0.47px',
            lineHeight: '1'
        }
    },
    props: {
        MuiAppBar: {
            color: 'transparent',
            elevation: 2
        },
        MuiListItem: {},
        MuiPaper: {
            elevation: 1
        }
    },
    overrides: {
        MuiToolbar: {
            root: {
                backgroundColor: '#FFF'
            }
        },
        MuiTabs: {
            root: {
                height: '30px',
                minHeight: 'auto'
            },
            indicator: {
                display: 'flex',
                justifyContent: 'flex-start',
                backgroundColor: 'transparent',
                height: '3px',
                '& > span': {
                    marginLeft: '0',
                    width: '90%',
                    height: '3px',
                    backgroundColor: '#75afe5'
                }
            }
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                padding: '0',
                minHeight: 'auto',
                minWidth: '140px',
                paddingBottom: '2px'
            },
            wrapper: {
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'flex-end',
                fontSize: '16px'
            },
            labelIcon: {
                minHeight: 'auto',
                paddingTop: 0
            },
            fullWidth: {
                minWidth: 'auto',
                flex: 1
            }
        },
        MuiGrid: {
            // ...Array(10).fill(0).map((ignore, index) => {
            //     return index + 1;
            // }).reduce((sizes:any, spacing:number) => {
            //     sizes["spacing-xs-" + spacing] = {
            //         marginTop: 0,
            //         marginBottom: 0,
            //         "& > $item": {
            //             paddingTop: 0,
            //             paddingBottom: 0
            //         }
            //     };
            //
            //     return sizes;
            // }, {})
        },
        MuiButton: {
            root: {
                minWidth: 'auto',
                borderRadius: '26px',
                textTransform: 'none',
                padding: '1px 16px'
            },
            sizeLarge: {},
            sizeSmall: {
                fontSize: '12px',
                '&$text': {
                    padding: '0 4px'
                }
            },
            text: {
                color: '#6292BF',
                padding: '0 5px',
                "&[aria-selected='true']": {
                    textDecoration: 'underline'
                },
                '& $endIcon': {
                    marginLeft: '5px'
                }
            },
            textSecondary: {
                fontSize: '16px'
            },
            containedSecondary: {
                fontSize: '16px'
            },
            outlined: {
                padding: '5px 12px',
                border: '1px solid',
                borderRadius: '5px'
            },
            iconSizeMedium: {
                '& > :first-child': {
                    fontSize: '16px'
                }
            },
            iconSizeSmall: {
                '& > :first-child': {
                    fontSize: '12px'
                }
            }
        },
        MuiTouchRipple: {
            root: {},
            rippleVisible: {},
            child: {
                backgroundColor: '#DCE5EC'
            }
        },
        MuiSlider: {
            root: {
              padding: '10px 0 5px 0',
              height: '4px',
            },
            rail: {
              height: '4px',
            },
            track: {
              height: '4px',
            },
            thumb: {
              marginTop: -7,
              marginLeft: -9,
              width: '18px',
              height: '18px',
              border: '1px solid',
              borderColor: 'currentColor',
              backgroundColor: '#FFF',
              // backgroundColor: "transparent",
              '&::after': {
                top: -10,
                left: -10,
                right: -10,
                bottom: -10,
              },
            },
        },
        MuiAccordion: {
            root: {
              margin: '0 0 15px 0',
              '&$expanded': {
                margin: '0 0 15px 0',
              },
            },
        },
        MuiAccordionSummary: {
            root: {
              minHeight: 'auto',
              '&$expanded': {
                minHeight: 'auto',
              },
            },
            content: {
              margin: '10px 0',
              '&$expanded': {
                margin: '10px 0',
              },
            },
        },
        MuiAvatar: {
            root: {},
        },
        MuiList: {
            root: {
              padding: 0,
              '&$dense': {
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
        },
        MuiListItem: {
            root: {
              '&$dense': {
                paddingTop: 0,
                paddingBottom: 0,
              },
              '&$selected span': {
                fontWeight: 'bold',
              },
            },
        },
        MuiListItemIcon: {
            root: {
              justifyContent: 'center',
              textAlign: 'center',
            },
        }
    }
});


export default theme;