import * as fns from "date-fns";

import {Model} from "src/makes/Model";

import {calcRate} from "src/tools";

/* eslint-disable */


export interface ISuggestAnalytic {
  id: string;
  suggestId: string;
  quadKeys: string[];
  rate: number;
  votes: number;
  votesPositive: number;
  votesNeutral: number;
  votesNegative: number;
  votesSurroundingTotal: number;
  date: string;
}

class SuggestAnalytic extends Model<ISuggestAnalytic> {
  static modelName = "suggest-analytic";

  static prepare(data:any, method:string):ISuggestAnalytic {
    const {
      votesTotal: votes,
      votesPositive,
      votesNeutral,
      votesNegative,
      ...other
    } = data;

    return {
      ...other,
      votes,
      votesPositive,
      votesNeutral,
      votesNegative,
      rate: calcRate(votesPositive, votesNeutral, votesNegative),
    };
  }

  static listSummary(analytics: ISuggestAnalytic[], res: any, query: any) {
    const minDate = analytics
      .map((analytic: ISuggestAnalytic) => {
        return analytic.date;
      })
      .sort((a, b) => {
        return a < b ? -1 : 1;
      })
      .find(() => true);

    let months = 0;

    if (minDate) {
      let date = fns.parse(minDate, 'yyyy-MM', new Date());

      if (fns.isValid(minDate)) {
        months = fns.differenceInCalendarMonths(new Date(), date) + 1;
      }
    }

    return {
      totals: Array(Math.max(months, 12))
        .fill(0)
        .map((ignore, index: number, arr: any[]) => {
          let date = fns.subMonths(new Date(), arr.length - 1 - index);

          return fns.format(date, 'yyyy-MM');
        })
        .map((date: string) => {
          let analytic = analytics.find((analytic: ISuggestAnalytic) => {
            return analytic.date === date;
          });

          if (analytic) {
            return {
              votes: analytic.votes,
              votesSurrounding: analytic.votesSurroundingTotal || 0,
              rate: analytic.rate,
              date: date,
            };
          }

          return {
            votes: 0,
            votesSurrounding: 0,
            rate: 0,
            date: date,
          };
        }),
    };
  }
}

export default SuggestAnalytic;
