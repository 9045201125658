import React, {
    useContext
} from "react";


const ResourceEditContext = React.createContext({
    name: "",
    id: null,
    record: null,
    setProcessing: (progress) => {},
    reload: async () => {}
});

export const useResourceEditContext = () => {
    return useContext(ResourceEditContext);
};

export const ResourceEditProvider = ResourceEditContext.Provider;
export const ResourceEditConsumer = ResourceEditContext.Consumer;
