import React from 'react';
import PropTypes from "prop-types";
import {Field} from "redux-form";

import {Input} from "src/views/rf-wrappers";


const InputField = (props) => {
    const {
        ...rest
    } = props;

    return (
        <Field {...rest}
          component={Input} />
    );
};

InputField.propTypes = {
    mask: PropTypes.string,
    validate: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.arrayOf(PropTypes.func)
    ]),
    label: PropTypes.string,
    name: PropTypes.string.isRequired
};


export default InputField;