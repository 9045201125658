import React from "react";
import PropTypes from "prop-types";
import {
    reduxForm,
    Form as ReduxForm
} from "redux-form";
import {
    Grid,
    Card,
    CardContent,
    Button
} from "@material-ui/core";

import {isRequired} from "src/tools";
import {InputField, SelectField} from "src/views/rf-fields";

import {
    User,
    USER_ROLE_ADMIN,
    USER_ROLE_GOVERNMENT,
    USER_ROLE_MANAGER,
    USER_ROLE_USER
} from "src/store/models/User";


const Form = (props) => {
    const {
        submitText,
        submitting,
        handleSubmit
    } = props;

    return (
        <ReduxForm noValidate={true} onSubmit={handleSubmit}>
            <Card>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <InputField
                              validate={[isRequired]}
                              disabled
                              type="text"
                              label="Email"
                              name="email" />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <SelectField
                              style={{ minWidth: 168 }}
                              options={[
                                {
                                    title: "Admin",
                                    value: USER_ROLE_ADMIN
                                },
                                {
                                    title: "Manager",
                                    value: USER_ROLE_MANAGER
                                },
                                {
                                    title: "Government",
                                    value: USER_ROLE_GOVERNMENT
                                },
                                {
                                    title: "User",
                                    value: USER_ROLE_USER
                                }
                              ]}
                              label="Role"
                              name="role" />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-start" style={{ marginTop: 20 }}>
                        <Button
                          type="submit"
                          className="my-2"
                          color="primary"
                          variant="contained"
                          size="large"
                          disabled={submitting}>
                            {submitText || 'Submit'}
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </ReduxForm>
    );
};

Form.propTypes = {
    form: PropTypes.string.isRequired,
    submitText: PropTypes.string,
    onSubmit: PropTypes.func
};


export default reduxForm({
    form: User.modelName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: false
})(Form);