import {IModule} from "kpdux";

import {parseJWT} from "src/tools";


type State = {
    token:string|null;
};

const app:IModule<State> = {
    state: (() => {
        const state:State = {
            token: null
        };

        state.token = localStorage.getItem("app:token") || null;

        return state;
    })(),
    getters: {
        token(state:State):State["token"] {
            const {token} = state;

            return token;
        },
        tokenData(state:State):any {
            const {token} = state;

            return token ? parseJWT(token) : {};
        }
    },
    mutations: {
        setToken(state:State, token:State["token"]) {
            if(token) {
                localStorage.setItem("app:token", token);
            }
            else {
                localStorage.removeItem("app:token");
            }

            state.token = token;
        }
    }
};


export type {State as AppState};

export {app};