import {IModule} from "kpdux";


const storage = {
    getters: {
        getItem() {
            return (key:string, defaultValue?:any):any|null => {
                let value = localStorage.getItem(key);

                if(value) {
                    try {
                        return JSON.parse(value);
                    }
                    catch(ignore) {
                        return value;
                    }
                }

                if(defaultValue !== "undefined") {
                    return defaultValue;
                }

                return null;
            };
        }
    },
    actions: {
        setItem(key:string, value:any) {
            if(value === null) {
                localStorage.removeItem(key);
            }
            else {
                const prepare = typeof value === "object"
                    ? JSON.stringify(value)
                    : value;

                localStorage.setItem(key, prepare);
            }
        },
        removeItem(key:string) {
            localStorage.removeItem(key);
        }
    }
} as IModule<any>;


export {storage};