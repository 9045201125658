import {type} from "os";
import _ from "lodash";

const STATUS_SUCCESS = "OK";
const STATUS_ERROR = "ERROR";

type STATUS_SUCCESS = typeof STATUS_SUCCESS;
type STATUS_ERROR = typeof STATUS_ERROR;

type IResponseSuccess = {
    status:STATUS_SUCCESS|STATUS_ERROR;
    message?:string;
};
type IResponseError = {
    status:STATUS_SUCCESS|STATUS_ERROR;
    message?:string;
};

type IResponseGetOne<IModel> = Promise<IResponseSuccess & {
    item?:IModel;
} | IResponseError>;
type IResponseGetList<IModel> = Promise<IResponseSuccess & {
    total:number;
    items:IModel[];
    summary?:any;
} | IResponseError>;
type IResponseCreateOne<IModel> = Promise<IResponseSuccess & {
    item:IModel;
} | IResponseError>;
type IResponseUpdateOne<IModel> = Promise<IResponseSuccess & {
    item?:IModel;
} | IResponseError>;
type IResponseRemoveOne<IModel> = Promise<IResponseSuccess & {
} | IResponseError>;
type IResponseRemoveMany<IModel> = Promise<IResponseSuccess & {
} | IResponseError>;

class Model<IModel = any> {
    static modelName:string = "";
    static accessType:string = "dashboard";

    orm:any;

    get modelName():string {
        // @ts-ignore
        return this.constructor.modelName;
    }

    get accessType():string {
        // @ts-ignore
        return this.constructor.accessType;
    }

    get getters() {
        return this.orm.getters;
    }

    get rootGetters() {
        return this.orm.rootGetters;
    }

    constructor(orm:any) {
        this.orm = orm;
    }

    async dispatch(...args:any[]) {
        return this.orm.dispatch(...args);
    }

    async _getOne(id:any):Promise<any> {
        return this.dispatch("api/get", `/api/${this.accessType}/${this.modelName}/${id}`, {
            headers: {
                Authorization: `Bearer ${this.rootGetters.auth.token}`
            }
        });
    }

    async getOne(id:any):IResponseGetOne<IModel> {
        return {
            status: STATUS_ERROR
        };
    }

    async _getList(query:any = {}):Promise<any> {
        return this.dispatch("api/get", `/api/${this.accessType}/${this.modelName}`, {
            headers: {
                Authorization: `Bearer ${this.rootGetters.auth.token}`
            },
            data: query
        });
    }

    async getFilteredList(query:any = {}):Promise<any> {
        const {
            page,
            count,
            ...body
        } = query;

        const newData = new FormData()
        Object.keys(body).forEach((key:any) => {
            if(Array.isArray(body[key]) && !_.isEmpty(body[key][0])) {
                newData.append(`${key}[]`, body[key]);
            } else if(!_.isEmpty(body[key]) && typeof body[key] !== "object") {
                newData.append(key, body[key]);
            }
        })

        return this.dispatch("api/post", `/api/${this.accessType}/${this.modelName}`, {
            headers: {
                Authorization: `Bearer ${this.rootGetters.auth.token}`
            },
            data: newData,
            query: {
                page,
                count
            },
            isCustomFormData: true,
        });
    }

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<IModel> {
        return {
            status: STATUS_ERROR
        };
    }

    async _createOne(data:any) {
        return this.dispatch("api/post", `/api/${this.accessType}/${this.modelName}/create`, {
            headers: {
                Authorization: `Bearer ${this.rootGetters.auth.token}`
            },
            data
        });
    }

    async createOne(data:any):IResponseCreateOne<IModel> {
        return {
            status: STATUS_ERROR
        };
    }

    async _updateOne(id:any, data:any) {
        return this.dispatch("api/post", `/api/${this.accessType}/${this.modelName}/update/${id}`, {
            headers: {
                Authorization: `Bearer ${this.rootGetters.auth.token}`
            },
            data
        });
    }

    async updateOne(id:any, data:any):IResponseUpdateOne<IModel> {
        return {
            status: STATUS_ERROR
        };
    }

    async _removeOne(id:any, data:any):Promise<any> {
        return this.dispatch("api/post", `/api/${this.accessType}/${this.modelName}/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${this.rootGetters.auth.token}`
            },
            data
        })
    }

    async removeOne(id:any, data:any = {}):IResponseRemoveOne<IModel> {
        return {
            status: STATUS_ERROR
        };
    }

    async _removeMany(data:[]):Promise<any> {
        const newData = new FormData()
        data.forEach(function (element) {
            newData.append('id[]', element);
        })

        return this.dispatch("api/post", `/api/${this.accessType}/${this.modelName}/delete`, {
            headers: {
                Authorization: `Bearer ${this.rootGetters.auth.token}`
            },
            data: newData,
            isCustomFormData: true,
        })
    }

    async removeMany(data:any):IResponseRemoveMany<IModel> {
        return {
            status: STATUS_ERROR
        };
    }

    toString():string {
        return "";
    }

    static prepare(item:any, method:string) {
        return item;
    }

    static listSummary(items:any, res:any, query:any):any {
        return {};
    }
}


export {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR
};

export type {
    IResponseGetOne,
    IResponseGetList,
    IResponseCreateOne,
    IResponseUpdateOne,
    IResponseRemoveOne,
    IResponseRemoveMany
};

export default Model;
