import React from "react";
import PropTypes from "prop-types";
import {
    useLocation,
    matchPath,
    Link
} from "react-router-dom";
import {
    makeStyles,
    Paper,
    Breadcrumbs,
    Typography
} from "@material-ui/core";
import {
    NavigateNext as NavigateNextIcon
} from "@material-ui/icons";


const PageTitle = (props) => {
    const {
        style,
        titleHeading = "",
        titleDescription = "",
        breadcrumbs = []
    } = props;

    const classes = useStyles();

    const {
        pathname
    } = useLocation();

    return (
        <Paper className={classes.root} square elevation={2}>
            <div style={style}>
                <div className={classes.heading}>
                    <Typography variant="h1"
                      className={classes.title}>
                        {titleHeading}
                    </Typography>

                    {titleDescription && (
                        <Typography variant="h5"
                          className={classes.description}>
                            {titleDescription}
                        </Typography>
                    )}
                </div>

                {breadcrumbs.length > 0 ? (
                    <Breadcrumbs
                      className="mt-4"
                      aria-label="breadcrumb"
                      separator={<NavigateNextIcon fontSize="small" />}
                      maxItems={4}>
                        {breadcrumbs.filter(breadcrumb => {
                            if(breadcrumb.path) {
                                let match = matchPath(pathname, {
                                      path: breadcrumb.path
                                  });

                                return Boolean(match);
                            }

                              return true;
                        }).map((breadcrumb, index) => {
                            return (
                                <Link key={index}
                                  color="inherit"
                                  to={breadcrumb.to}>
                                    {breadcrumb.title}
                                </Link>
                            );
                        })}
                    </Breadcrumbs>
                ) : null}
            </div>
        </Paper>
    );
}

PageTitle.propTypes = {
    titleHeading: PropTypes.node,
    titleDescription: PropTypes.node,
    breadcrumbs: PropTypes.array,
    className: PropTypes.string
};


const useStyles = makeStyles(() => {
    return {
        root: {
            padding: 10
        },
        heading: {},
        title: {},
        description: {}
    };
});

export default PageTitle;