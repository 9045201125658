import React, { useContext } from 'react';

export const ListContext = React.createContext({
  name: '',
  setProcessing: progress => {},
  reload: async () => {},
});

export const useListContext = () => {
  return useContext(ListContext);
};

export const EntityListProvider = ListContext.Provider;
export const EntityListConsumer = ListContext.Consumer;
