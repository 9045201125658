import React from "react";

import "./styles.scss";


const PleaseWaitForApprove:React.FC = () => {
    return (
        <div
          className="PleaseWaitForApprove">
            Please wait for account to be approved.
        </div>
    );
};


export default PleaseWaitForApprove;