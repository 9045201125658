import React, {
    useState,
    useEffect
} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as lodash from "lodash";

import {
    FormControl,
    InputLabel,
    Input,
    FormHelperText
} from "@material-ui/core";

import {
    EntityList
} from "../../core";

import {
    Dialog
} from "../../blocks";

import store from "../../../store";


const RelativeOneWrapper = (props) => {
    const {
        meta,
        input,
        label,
        relativeName,
        relativeTitle,
        headers,
        disabled = false,
        ...rest
    } = props;

    const {
        value
    } = input;

    const [id] = useState("relative-one-id-" + RelativeOneWrapper.COUNT++);
    const [open, setOpen] = useState(false);
    const item = props.byId(relativeName, value);

    let displayValue = lodash.get(item, relativeTitle, "");
    const error = meta.touched ? meta.error : "";

    useEffect(() => {
        if(value) {
            props.fetch(relativeName, value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpen = () => {
        setOpen(true);

        input.onFocus();
    };

    const handleClose = () => {
        setOpen(false);

        input.onBlur();
    };

    return (
        <React.Fragment>
            <Dialog
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
              activator={(props) => {
                return (
                    <FormControl
                      className="w-100"
                      error={!!error}>
                        <input type="hidden"
                          {...input} />

                        <InputLabel
                          id={id + "-label"}
                          htmlFor={id}>
                            {label}
                        </InputLabel>

                        <Input {...disabled ? {} : props}
                          id={id}
                          className="w-100"
                          readOnly={true}
                          value={displayValue} />

                        <FormHelperText
                          id={id + "-text"}>
                            {error}
                        </FormHelperText>
                    </FormControl>
                );
              }}>
                <EntityList
                  name={relativeName}
                  showTitle={false}
                  headers={headers || [
                    {
                        title: "ID",
                        name: "id"
                    }
                  ]}
                  {...rest}
                  onClickRow={(item) => {
                    if(item) {
                        input.onChange(item.id);
                    }

                    handleClose();
                  }} />
            </Dialog>
        </React.Fragment>
    );
};

RelativeOneWrapper.COUNT = 0;

RelativeOneWrapper.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    relativeName: PropTypes.string.isRequired,
    relativeTitle: PropTypes.string.isRequired
};


const putState = () => {
    return {
        byId: store.orm.getters.byId
    };
};

const putActions = () => {
    return {
        fetch: store.orm.fetch
    };
};

export default connect(putState, putActions)(RelativeOneWrapper);