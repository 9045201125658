import {TileParameter} from "src/store/models/TileParameter";


export const ROUTES = {
    main: "/",
    home: "/",
    login: "/login",
    logout: "/logout",
    tileParameter: `/${TileParameter.modelName}`
};
