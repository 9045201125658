import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetOne,
    IResponseGetList,
    IResponseCreateOne,
    IResponseUpdateOne,
    IResponseRemoveOne
} from "src/makes/Model";


interface ISuggestCategory {
    id:string;
    label:string;
    labels:{
        [lang:string]:string;
    };
}

class SuggestCategory extends Model<ISuggestCategory> {
    static modelName:string = "suggest-category";

    async getOne(id:any):IResponseGetOne<ISuggestCategory> {
        const res = await this._getOne(id);

        if(res.httpCode === 200) {
            const {
                response: {
                    label_translations: labels = {},
                    ...otherData
                }
            } = res;

            return {
                status: STATUS_SUCCESS,
                item: {
                    labels: !Array.isArray(labels) ? labels : {},
                    ...otherData
                }
            };
        }

        const {
            response: {
                message
            }
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<ISuggestCategory> {
        const res = await this._getList(query);

        if(typeof res.count === "number") {
            const {
                count: total,
                data: items
            } = res;

            return {
                status: STATUS_SUCCESS,
                total,
                items: items.map((item:any) => {
                    const {
                        title,
                        ...otherData
                    } = item;

                    return {
                        label: title,
                        ...otherData
                    };
                })
            };
        }

        return {
            status: STATUS_ERROR
        };
    }

    async createOne(data:any):IResponseCreateOne<ISuggestCategory> {
        const res = await this.dispatch("api/post", `/api/dashboard/${SuggestCategory.modelName}/create`, {
            headers: this.rootGetters.data.headers,
            data: data
        });

        if(res.httpCode === 200 || res.httpCode === 201) {
            const {
                response: {
                    labels = data.labels || {},
                    ...otherData
                }
            } = res;

            return {
                status: STATUS_SUCCESS,
                message: "Suggest category was created.",
                item: {
                    ...otherData,
                    labels: !Array.isArray(labels) ? labels : {}
                }
            };
        }

        const {
            response: {
                message
            }
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async updateOne(id:any, data:any):IResponseUpdateOne<ISuggestCategory> {
        const res = await this.dispatch("api/post", `/api/dashboard/${SuggestCategory.modelName}/update/${id}`, {
            headers: this.rootGetters.data.headers,
            data
        });

        if(res.httpCode === 200) {
            const {
                response: {
                    labels = data.labels || {},
                    ...otherData
                }
            } = res;

            return {
                status: STATUS_SUCCESS,
                message: "Suggest category was updated.",
                item: {
                    labels: !Array.isArray(labels) ? labels : {},
                    ...otherData
                }
            };
        }

        const {
            response: {
                message
            }
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async removeOne(id:any, data:any = {}):IResponseRemoveOne<ISuggestCategory> {
        const res = await this.dispatch("api/post", `/api/dashboard/${SuggestCategory.modelName}/delete/${id}`, {
            headers: this.rootGetters.data.headers,
            data
        });

        if(res.httpCode === 200) {
            return {
                status: STATUS_SUCCESS,
                message: "Suggest category was deleted."
            };
        }

        const {
            response: {
                message
            }
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }
}


export type {ISuggestCategory};

export {SuggestCategory};
