import React from "react";
import PropTypes from "prop-types";

import {
    InputAuto
} from "../../inputs";


const InputAutoWrapper = (props) => {
    const {
        meta,
        input,
        label,
        options = [],
        ...rest
    } = props;

    return (
        <InputAuto
          {...rest}
          {...input}
          label={label}
          error={meta.touched ? meta.error : ""}
          options={options}
          onChange={(e, option) => {
            if(option) {
                input.onChange(option.value);
            }
            else {
                input.onChange("");
            }
          }}
          onFocus={(e, option) => {
            input.onFocus();
          }}
          onBlur={(e, option) => {
            input.onBlur();
          }} />
    );
};

InputAutoWrapper.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    raw: PropTypes.bool,
    options: PropTypes.array.isRequired
};


export default InputAutoWrapper;