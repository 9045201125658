import React from "react";
import { Badge } from "@material-ui/core";

import "./index.scss";

interface RateCircleProps {
  color?:string;
  value?:string;
  title?:string;
}

class RateCircle extends React.Component<RateCircleProps, any> {
  render() {
    const { color, value, title } = this.props;

    return (
      <Badge
        className='ate-circle'
        style={{
          backgroundColor: color,
        }}
        overlap='circle'
        badgeContent=''
        variant='dot'
      >
        <span className='rate-circle__value'>{value}</span>

        <span className='rate-circle__title'>{title}</span>
      </Badge>
    );
  }
}

export default RateCircle;
