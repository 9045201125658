import React from "react";


const HiddenInput = (props) => {
    const {
        ...rest
    } = props;

    return (
        <input
          type="hidden"
          {...rest} />
    );
};


export default HiddenInput;