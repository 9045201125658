import React from "react";


const DefaultLayout:React.FC = (props) => {
    const {
        children
    } = props;

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};


export default DefaultLayout;