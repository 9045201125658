import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Menu, List } from '@material-ui/core';

const VerticalMenu = props => {
  const { children, activator, open = false, onOpen, onClose } = props;

  const [isOpen, setOpen] = useState(open);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = () => {
    setOpen(true);

    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <Fragment>
      {activator &&
        activator({
          onClick: e => {
            setAnchorEl(e.currentTarget);
            handleOpen();
          },
        })}

      <Menu
        classes={{ list: 'p-0' }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        open={isOpen}
        onClose={handleClose}
      >
        <div className='overflow-hidden dropdown-menu-xl'>
          <List className='flex-column'>
            {children}

            {/*{
                            React.Children.map(children, (child, index) => {
                                return React.cloneElement(child, {
                                    key: index,
                                    ...child.props
                                });
                            })
                        }*/}
          </List>
        </div>
      </Menu>
    </Fragment>
  );
};

VerticalMenu.defaultProps = {
  activator: props => null,
};

VerticalMenu.propTypes = {
  activator: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default VerticalMenu;
