import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetOne,
    IResponseGetList,
    IResponseCreateOne,
    IResponseUpdateOne,
    IResponseRemoveOne
} from "src/makes/Model";


interface IDemandCategory {
  id:string;
  parentId?:string;
  icon?:string;
  title:string;
  label:string;
  is_suggested?:boolean;
}

class DemandCategory extends Model<IDemandCategory> {
    static modelName:string = "demand-category";
    static queryCount:string = "";
    static queryPage:string = "";

    static filters = {
        parentId: (category:IDemandCategory, parentId:string):boolean => {
            return category.parentId === parentId;
        }
    };

    async getOne(id:any):IResponseGetOne<IDemandCategory> {
        const res = await this._getOne(id);

        if(res.httpCode === 200) {
            const {
                response: {
                    parent_id,
                    label_translations: labels = {},
                    files,
                    ...otherData
                }
            } = res;

            const [file] = Array.isArray(files) ? files : [];

            const {
                links: {
                    sizes: {
                        original: {
                            view: icon = ""
                        } = {}
                    } = {}
                } = {}
            } = file || {};

            return {
                status: STATUS_SUCCESS,
                message: "",
                item: {
                    ...otherData,
                    labels: !Array.isArray(labels) ? labels : {},
                    parentId: parent_id,
                    icon
                }
            };
        }

        const {
            response: {
                message
            }
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async _getList(query:any = {}):Promise<any> {
        const {
            parentId
        } = query;

        if(parentId === "") {
            const {
                parentId,
                ...otherQuery
            } = query;

            return this.dispatch("api/get", `/api/${this.accessType}/${this.modelName}/root-categories`, {
                headers: {
                    Authorization: `Bearer ${this.rootGetters.auth.token}`
                },
                query: otherQuery
            });
        }

        return this.dispatch("api/get", `/api/${this.accessType}/${this.modelName}/list`, {
            headers: {
                Authorization: `Bearer ${this.rootGetters.auth.token}`
            },
            query
        });
    }

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<IDemandCategory> {
        const res = await this._getList(query);

        if(typeof res.count === "number") {
            const {
                count: total,
                data: items
            } = res;

            return {
                status: STATUS_SUCCESS,
                total,
                items: items.map((item:any) => {
                    const {
                        title,
                        label = title,
                        ...other
                    } = item;

                    return {
                        label,
                        ...other
                    };
                })
            };
        }

        return {
            status: STATUS_ERROR
        };
    }

    async createOne(data:any):IResponseCreateOne<IDemandCategory> {
        const {
            parentId,
            is_suggested,
            icon,
            ...other
        } = data;

        const res = await this.dispatch("api/post", `/api/dashboard/${DemandCategory.modelName}/create`, {
            headers: this.rootGetters.data.headers,
            data: {
                ...other,
                parent_id: parentId === "" ? null : parentId,
                ...icon instanceof File ? {
                    icon
                } : {},
                ...is_suggested ? {
                    is_suggested
                } : {}
            }
        });

        if(res.httpCode === 200 || res.httpCode === 201) {
            const {
                response: {
                    ...otherData
                }
            } = res;

            return {
                status: STATUS_SUCCESS,
                message: "Demand category was created.",
                item: {
                    ...otherData
                }
            };
        }

        const {
            response: {
                message = ""
            } = {}
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async updateOne(id:any, data:any):IResponseUpdateOne<IDemandCategory> {
        const {
            parentId,
            is_suggested,
            icon,
            ...other
        } = data;

        const res = await this.dispatch("api/post", `/api/dashboard/${DemandCategory.modelName}/update/${id}`, {
            headers: this.rootGetters.data.headers,
            data: {
                ...other,
                parent_id: parentId === "" ? null : parentId,
                ...icon instanceof File ? {
                    icon
                } : {},
                ...is_suggested ? {
                    is_suggested
                } : {}
            }
        });

        if(res.httpCode === 200) {
            return {
                status: STATUS_SUCCESS,
                message: "Demand category was updated."
            };
        }

        const {
            response: {
                message
            }
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async removeOne(id:any):IResponseRemoveOne<IDemandCategory> {
        const res = await this.dispatch("api/post", `/api/dashboard/${DemandCategory.modelName}/delete/${id}`, {
            headers: this.rootGetters.data.headers
        });

        const {
            response: {
                message = ""
            } = {}
        } = res;

        if(res.httpCode === 200) {
            return {
                status: STATUS_SUCCESS,
                message: message || "Demand category was deleted."
            };
        }

        return {
            status: STATUS_ERROR,
            message
        };
    }
}


export type {IDemandCategory};

export {DemandCategory};