import React from 'react';
import { generatePath, useParams, Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { useResourceContext } from '../Resource/hooks';

const CreateButton = () => {
  const { createPath } = useResourceContext();

  const params = useParams();

  return (
    <Button component={Link} size='small' variant='contained' color='primary' to={generatePath(createPath, params)}>
      Add new entry
    </Button>
  );
};

export default CreateButton;
