import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../inputs';

const InputWrapper = props => {
  const { meta, input, label, ...rest } = props;

  return <Input {...rest} {...input} label={label} error={meta.touched ? meta.error : ''} />;
};

InputWrapper.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  mask: PropTypes.string,
  label: PropTypes.string,
};

export default InputWrapper;
