import React from "react";
import {compose} from "redux";
import {
    withRouter,
    matchPath,
    RouteComponentProps
} from "react-router";
import {
    createStyles,
    withStyles,
    Tabs
} from "@material-ui/core";


interface Props extends React.PropsWithChildren<any> {
    value?:number;
    onChange?:(e:any, value:number) => void;
    variant?:"standard"|"fullWidth"|"scrollable";
}

interface CProps extends Props, RouteComponentProps {}


const PageTabs:React.FC<CProps> = (props:CProps) => {
    const {
        children,
        value = 0,
        location: {
            pathname
        },
        staticContext,
        history,
        match,
        ...rest
    } = props;

    let activeTab = -1;

    React.Children.map(children, (child:any, index:number) => {
        const {
            exact = false,
            path
        } = child.props;

        if(path) {
            const match = matchPath(pathname, {
                exact: exact,
                path: path
            });

            if(match) {
                activeTab = index;
            }
        }
    });

    return (
        <Tabs
          {...rest}
          value={activeTab >= 0 ? activeTab : value}
          TabIndicatorProps={{children: <span />}}>
            {children}
        </Tabs>
    );
};


const styles = () => createStyles({});


export default compose<React.ComponentType<Props>>(
    withRouter,
    withStyles(styles)
)(PageTabs);