import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {
    reduxForm,
    Form as ReduxForm
} from "redux-form";
import {
    Grid,
    Card,
    CardContent,
    Button
} from "@material-ui/core";

import {isRequired} from "src/tools";
import {useORM} from "src/hooks";
import {
    FileField,
    InputField,
    SelectField,
    CheckboxField
} from "src/views/rf-fields";

import store from "src/store";
import {DemandCategory} from "src/store/models/DemandCategory";


const Form = (props) => {
    const {
        submitText,
        submitting,
        handleSubmit
    } = props;

    const orm = useORM();

    const categories = orm.find(DemandCategory.modelName, {
        parentId: ""
    });

    useEffect(() => {
        const loadData = async () => {
            await store.orm.search(DemandCategory.modelName, {
                parentId: ""
            });
        };

        loadData();
    }, []);

    return (
        <ReduxForm noValidate={true} onSubmit={handleSubmit}>
            <Card>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <InputField
                              validate={[isRequired]}
                              type="text"
                              label="En title"
                              name="labels[en]" />
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                              validate={[isRequired]}
                              type="text"
                              label="Da title"
                              name="labels[da]" />
                        </Grid>

                        <Grid item xs={12}>
                            <CheckboxField
                              label="Is suggested"
                              name="is_suggested" />
                        </Grid>

                        <Grid item xs={12}>
                            <SelectField
                              style={{
                                minWidth: 168
                              }}
                              options={[
                                {
                                    title: "No category",
                                    value: ""
                                },
                                ...categories.map((category) => {
                                    return {
                                        title: `${category.label} (${category.id})`,
                                        value: category.id
                                    };
                                  })
                              ]}
                              label="Parent ID"
                              name="parentId" />
                        </Grid>

                        <Grid item xs={12}>
                            <FileField
                              type="file"
                              label="Icon"
                              name="icon" />
                        </Grid>
                    </Grid>

                    <Grid container className="mt-3" justifyContent="flex-start">
                        <Button
                          type="submit"
                          className="my-2"
                          color="primary"
                          variant="contained"
                          size="large"
                          disabled={submitting}>
                            {submitText || "Submit"}
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </ReduxForm>
    );
};

Form.propTypes = {
    form: PropTypes.string.isRequired,
    submitText: PropTypes.string,
    onSubmit: PropTypes.func,
    token: PropTypes.string.isRequired
};


export default reduxForm({
    form: DemandCategory.modelName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: false
})(Form);