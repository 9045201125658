import React, {
    MouseEvent,
    ChangeEvent,
    ChangeEventHandler
} from "react";
import {
    createStyles,
    withStyles,
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableFooter,
    TablePagination,
    Paper,
    CircularProgress,
    LinearProgress,
    Backdrop
} from "@material-ui/core";

import {
    GridTableHead,
    GridTableRow
} from "./blocks";

import "./index.scss";
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {lighten, makeStyles, Theme} from "@material-ui/core/styles";


type Props = {
    classes?: any;
    headers?: any[];
    headActions?: any[];
    rowActions?: any[];
    bottomActions?: any[];
    data?: any[];
    title?: string;
    loading?: boolean;
    limit?: number;
    page?: number;
    perPage?: number;
    orderBy?: string;
    orderWay?: string;
    pagination?: boolean;
    progress?: boolean;
    selected?: string[];
    isCheckboxActions?: boolean,
    onClickRow?: (event: MouseEvent<unknown>, name: string) => void;
    onChangePage?: (page:number) => void;
    onChangePerPage?: (perPage:number) => void;
    onChangeOrder?: (orderBy:string, orderWay:string) => void;
    onDelete?: () => void;
    onSelectAllClick?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const GridTable:React.FC<Props> = (props:Props) => {
    const {
        classes,
        headers = [],
        data = [],
        selected = [],
        title = "",
        loading = false,
        limit = 0,
        page = 0,
        perPage = 5,
        orderBy = null,
        orderWay = null,
        pagination = true,
        progress = false,
        isCheckboxActions = false,
        rowActions = [],
        headActions = [],
        bottomActions = [],
        onClickRow,
        onChangePage,
        onChangePerPage,
        onChangeOrder,
        onDelete,
        onSelectAllClick
    } = props;

    const handleChangePage = (event:MouseEvent<HTMLButtonElement>|null, newPage:number) => {
        if(onChangePage) {
            onChangePage(newPage);
        }
    };

    const handleChangePerPage = (e:ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if(onChangePerPage) {
            onChangePerPage(parseInt(e.target.value));
        }
    };

    const handleOrder = (name:string) => {
        if(onChangeOrder) {
            const isAsc = orderBy === name && orderWay === "asc";

            onChangeOrder(name, isAsc ? "desc" : "asc");
        }
    };

    const useToolbarStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(1),
            },
            highlight:
                theme.palette.type === 'light'
                    ? {
                        color: theme.palette.secondary.main,
                        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                    }
                    : {
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.secondary.dark,
                    },
            title: {
                flex: '1 1 100%',
            },
        }),
    );

    interface EnhancedTableToolbarProps {
        numSelected: number;
    }

    const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
        const classes = useToolbarStyles();
        const { numSelected } = props;

        return (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                {numSelected > 0 ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                ) : null}
                {numSelected > 0 ? (
                    <Tooltip title="Delete" onClick={onDelete}>
                        <IconButton aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Toolbar>
        );
    };

    interface SelectAllCheckboxProps {
        numSelected: number;
        rowCount: number;
        onSelectAllClick?: (event: ChangeEvent<HTMLInputElement>) => void;
    }

    const SelectAllCheckbox = (props: SelectAllCheckboxProps) => {
        const {onSelectAllClick, numSelected, rowCount } = props;

        return (
            <TableCell padding="checkbox">
                <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                />
            </TableCell>
        )
    }

    // @ts-ignore
    return (
        <TableContainer
          component={Paper}
          className="card-box TableContainer">
            {isCheckboxActions && (
                <EnhancedTableToolbar numSelected={selected.length} />
            )}
            <Grid container className="card-header">
                {title ? (
                    <Grid item className="card-header--title">
                        {title}
                    </Grid>
                ) : (
                    <Grid item />
                )}

                <Grid item>
                    <Grid container />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    {((loading && data.length) || progress) && (
                        <LinearProgress />
                    )}
                </Grid>
            </Grid>

            <Grid container className="card-footer" justifyContent="flex-end">
                {headActions.map((headAction, index:number) => {
                    return (
                        <Grid key={index}
                          item
                          style={{margin: 4}}
                          className="px-1">
                            {headAction}
                        </Grid>
                    );
                })}
            </Grid>

            <Table
              className={classes.table}
              aria-disabled="true"
              aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        {isCheckboxActions && (
                            <SelectAllCheckbox
                                numSelected={selected.length}
                                rowCount={data.length}
                                onSelectAllClick={onSelectAllClick} />
                        )}

                        {headers.map((header, index:number) => {
                            return (
                                <GridTableHead
                                  key={index}
                                  orderBy={orderBy}
                                  order={orderWay}
                                  header={header}
                                  onClickSort={handleOrder} />
                            );
                        })}

                        {rowActions.length && (
                            <GridTableHead
                              header={{
                                title: "Actions",
                                align: "right",
                                width: 100
                              }} />
                        )}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {loading && data.length === 0 && (
                        <TableRow>
                            <TableCell align="center" colSpan={headers.length + (rowActions.length > 0 ? 1 : 0)}>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}

                    {!loading && data.length === 0 && (
                        <TableRow>
                            <TableCell align="center" colSpan={headers.length + (rowActions.length > 0 ? 1 : 0)}>
                                No data
                            </TableCell>
                        </TableRow>
                    )}

                    {!loading && data.length > 0 && (
                        data.map((record, index:number) => {
                            return (
                                <GridTableRow
                                  key={index}
                                  headers={headers}
                                  rowActions={rowActions}
                                  record={record}
                                  selected={selected}
                                  isCheckboxActions={isCheckboxActions}
                                  onClick={onClickRow} />
                            );
                        })
                    )}
                </TableBody>

                <TableFooter>
                    {pagination ? (
                        <TableRow>
                            <TablePagination
                              rowsPerPage={perPage}
                              rowsPerPageOptions={[5, 10, 25, 50]}
                              count={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangePerPage} />
                        </TableRow>
                    ) : null}
                </TableFooter>
            </Table>

            <Grid container className="card-footer" justifyContent="flex-end">
                {bottomActions.map((bottomAction, index) => {
                    return (
                        <Grid
                          key={index}
                          item
                          style={{margin: 4}}
                          className="px-1">
                            {bottomAction}
                        </Grid>
                    );
                })}
            </Grid>

            <Backdrop
              style={{
                position: "absolute",
                zIndex: 100
              }}
              open={progress} />
        </TableContainer>
    );
};


const styles = () => {
    return createStyles({
        table: {
            minWidth: 500
        }
    });
};

export default withStyles(styles)(GridTable);
