import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import {
  AttachFile as AttachFileIcon,
  Close as CloseIcon
} from "@material-ui/icons";

import { ConvertedFileToBase64 } from "src/views/blocks/ConvertedFileToBase64";
import noImageIcon from "src/assets/images/no-image.png";

import "./index.scss";


const FileWrapper = (props) => {
  const { meta, input, label } = props;

  const [id] = useState('file-wrapper-id-' + FileWrapper.COUNT++);
  const inputRef = useRef();

  const error = meta.touched ? meta.error : '';

  return (
    <FormControl className='w-100 FileWrapper' error={!!error}>
      <InputLabel id={id + '-label'} className='icon-label'>
        {label}
      </InputLabel>

      {input.value ? (
        <>
          {typeof input.value === 'object' ? (
            <ConvertedFileToBase64 file={input.value} style={{ maxWidth: 70, maxHeight: 70 }} />
          ) : (
            <div className="existing-icon-wrapper">
              <img
                style={{ background: '#000' }}
                alt=""
                src={input.value} />
            </div>
          )}

          <Input
            inputRef={inputRef}
            startAdornment={
              <InputAdornment position='start'>
                <IconButton size='small'>
                  <AttachFileIcon />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  size='small'
                  onClick={() => {
                    input.onChange('');
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            }
            disabled={true}
            value={typeof input.value === "object" ? input.value.name : input.value}
          />
        </>
      ) : (
        <>
          <div className="existing-icon-wrapper">
            <img
              alt=""
              src={noImageIcon} />
          </div>

          <Input
            type='file'
            inputRef={inputRef}
            id={id}
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  onClick={() => {
                    if (inputRef && inputRef.current) {
                      inputRef.current.click();
                    }
                  }}
                >
                  <AttachFileIcon />
                </IconButton>
              </InputAdornment>
            }
            onChange={e => {
              if (e.target.files.length > 0) {
                input.onChange(e.target.files[0]);
              } else {
                input.onChange(null);
              }
            }}
            value=''
          />
        </>
      )}
    </FormControl>
  );
};
FileWrapper.COUNT = 0;

FileWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default FileWrapper;
