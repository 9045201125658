import React from "react";
import {compose} from "redux";
import {
    createStyles,
    withStyles,
    WithStyles,
    Drawer
} from "@material-ui/core";


interface Props {}

interface CProps extends Props, WithStyles {}

class DebugPanel extends React.Component<CProps, any> {
    _openHandler = (e:MouseEvent) => e.buttons === 4 ? this.open() : false;

    constructor(props:CProps) {
        super(props);

        this.state = {
            open: false
        };
    }

    componentDidMount() {
        window.addEventListener("mousedown", this._openHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("mousedown", this._openHandler);
    }

    open() {
        this.setState({
            open: true
        });
    }

    close() {
        this.setState({
            open: false
        });
    }

    toggle() {
        this.state.open ? this.close() : this.open();
    }

    render() {
        const {
            classes,
            children
        } = this.props;

        const {
            open
        } = this.state;

        return (
            <Drawer
              classes={{paper: classes.drawerPaper}}
              anchor="bottom"
              open={open}
              onClose={() => this.close()}>
                {children}
            </Drawer>
        );
    }
}


const styles = () => createStyles({
    drawerPaper: {
        maxHeight: "60vh"
    }
});

export default compose<React.ComponentType<Props>>(
    withStyles(styles)
)(DebugPanel);