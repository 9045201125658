import React from "react";
import {compose} from "redux";
import {
    Avatar
} from "@material-ui/core";


interface Props {
    style?:any;
    className?:string;
    alt?:string;
    color?:string;
    src?:string;
}

interface CProps extends Props {}


class SvgImage extends React.Component<CProps, any> {
    render() {
        const {
            style,
            className,
            alt,
            color,
            src
        } = this.props;

        return src ? (
            new RegExp("\\.svg$").test(src) ? (
                <object
                  style={style}
                  className={className}
                  type="image/svg+xml"
                  data={src}>
                    <param name="color" value={color} />
                </object>
            ) : (
                <Avatar
                  style={style}
                  classes={{root: className}}
                  variant="square"
                  alt={alt}
                  src={src} />
            )
        ) : (
            <Avatar
              style={style}
              classes={{root: className}}
              variant="square"
              alt={alt} />
        );
    }
}


export default compose<React.ComponentType<Props>>(

)(SvgImage);