import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import FileWrapper from '../../rf-wrappers/FileWrapper';

const ResourceField = props => {
  return <Field component={FileWrapper} {...props} />;
};

ResourceField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default ResourceField;
