import React from "react";
import PropTypes from "prop-types";
import {
    FormControlLabel,
    Checkbox as UICheckbox
} from "@material-ui/core";


const Checkbox = (props) => {
    const {
        label,
        value = false,
        ...rest
    } = props;

    return (
        <div className="w-100">
            <FormControlLabel
              label={label}
              control={
                <UICheckbox
                  checked={value === "" ? false : value}
                  {...rest} />
              } />
        </div>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string
};


export default Checkbox;