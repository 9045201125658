import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetList,
    IResponseGetOne,
    IResponseUpdateOne
} from "src/makes/Model";


interface IUser {
    id:string;
    annual_income:string;
    birth_year:number;
    created_at:string;
    display_name:string;
    education:string;
    email:string;
    gender:string;
    intro:string;
    political_views:string;
    rating:number;
    transport_types:Array<string>;
    updated_at:string;
    work_status:string;
    roles:Array<{
      id:string;
      name:'admin' | 'manager' | 'government';
      pivot:{
        user_id:string;
        role_id:string;
      };
    }>;
}

class User extends Model<IUser> {
    static modelName:string = "users";
    static accessType:string = "admin";

    async getOne(id:any):IResponseGetOne<IUser> {
        const res = await this._getOne(id);

        if(res) {
            const {
                response: item
            } = res;

            const {
                roles: [role = null] = []
            } = item;

            return {
                status: STATUS_SUCCESS,
                item: {
                    ...item,
                    role: role ? role.name : ""
                }
            };
        }

        const {
            response: {
                message = ""
            } = ""
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<IUser> {
        const res = await this._getList({
            ...query,
            ...count > 0 || page > 0 ? {
                count,
                page: page + 1
            } : {}
        });

        const {
            response: {
                message = ""
            } = {}
        } = res;

        if(res.httpCode === 200) {
            const {
                response: {
                    total,
                    data: items
                }
            } = res;

            return {
                status: STATUS_SUCCESS,
                total,
                items: items.map((user:IUser) => {
                    const {
                        roles: [role] = []
                    } = user;

                    return {
                        ...user,
                        role: role ? role.name : ""
                    };
                })
            };
        }

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async updateOne(id:any, data:any):IResponseUpdateOne<IUser> {
        const item = this.getters.byId(User.modelName, id);

        const {
            role
        } = data;

        if(item.role !== role) {
            const res = await this.dispatch("api/post", `/api/admin/user-role/update/${id}`, {
                headers: this.rootGetters.data.headers,
                data: {
                    role
                }
            });

            const {
                response: {
                    message = ""
                } = {}
            } = res;

            if(res.httpCode === 200) {
                return {
                    status: STATUS_SUCCESS,
                    message: "User role was changed.",
                    item: {
                        ...item,
                        role
                    }
                };
            }

            return {
                status: STATUS_ERROR,
                message
            };
        }

        return {
            status: STATUS_ERROR
        };
    }
}

const USER_ROLE_ADMIN = "admin";
const USER_ROLE_USER = "user";
const USER_ROLE_MANAGER = "manager";
const USER_ROLE_GOVERNMENT = "government";


export type {IUser};

export {
    User,
    USER_ROLE_ADMIN,
    USER_ROLE_USER,
    USER_ROLE_MANAGER,
    USER_ROLE_GOVERNMENT
};
