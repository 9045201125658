import React from "react";
import PropTypes from "prop-types";
import {Field} from "redux-form";

import {Select} from "src/views/rf-wrappers";


const SelectField = (props) => {
    return (
        <Field {...props}
          component={Select} />
    );
};

SelectField.propTypes = {
    disabled: PropTypes.bool,
    validate: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.func)
    ]),
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired
};


export default SelectField;