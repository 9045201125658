import React, {useEffect} from "react";
import {useHistory} from "react-router";
import {
    makeStyles,
    Grid
} from "@material-ui/core";

import {useAuth} from "src/hooks";

import {LoginForm} from "src/views/forms";

import {ROUTES} from "src/router";
import store from "src/store";


const AuthLogin:React.FC = (props) => {
    const classes = useStyles(props);
    const history = useHistory();
    const auth = useAuth();

    useEffect(() => {
        if(auth.isLoggedIn) {
            history.replace(ROUTES.main);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (data:any) => {
        await store.auth.login(data);

        history.push(ROUTES.main);
    };

    return (
        <Grid container
          className={classes.root}
          alignContent="center"
          justifyContent="center">
            <LoginForm
              onSubmit={handleSubmit} />
        </Grid>
    );
};


const useStyles = makeStyles(() => {
    return {
        root: {
            height: "100%"
        }
    };
});

export default AuthLogin;