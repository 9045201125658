import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';

import './index.scss';

interface Props {
  page?:number;
  count?:number;
  className?:string;
  size?:'large' | 'medium' | 'small';
  onChange?:(page:number) => void;
}

class Pagination extends React.Component<Props, any> {
  next = () => {
    const { page = 0, count = 0, onChange } = this.props;

    if (onChange && count > 0) {
      onChange(page < count - 1 ? page + 1 : 0);
    }
  };

  prev = () => {
    const { page = 0, count = 0, onChange } = this.props;

    if (onChange && count > 0) {
      onChange(page > 0 ? page - 1 : count - 1);
    }
  };

  render() {
    const { className, size = 'medium', page = 0, count = 0 } = this.props;

    return (
      <Grid
        container
        className={['pagination', 'pagination--' + size, className].join(' ')}
        alignItems='center'
        alignContent='center'
      >
        <IconButton classes={{ root: 'pagination__button' }} onClick={() => this.prev()}>
          <ChevronLeftIcon fontSize='small' />
        </IconButton>

        <Typography className='pagination__text'>
          {page + 1} of {count}
        </Typography>

        <IconButton classes={{ root: 'pagination__button' }} onClick={() => this.next()}>
          <ChevronRightIcon fontSize='small' />
        </IconButton>
      </Grid>
    );
  }
}

export default Pagination;
