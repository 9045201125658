import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button} from "@material-ui/core";

import "./styles.scss";

import Dialog from "src/views/blocks/Dialog";


const DialogDelete = (props) => {
    const {
        description,
        activator
    } = props;

    const [isOpen, setOpen] = useState(false);
    const [processing, setProcessing] = useState(false);

    const openHandle = () => {
        setOpen(true);
    };

    const closeHandle = () => {
        setOpen(false);
    };

    const deleteHandle = async () => {
        setProcessing(true);
        setOpen(false);

        if(props.onDelete) {
            props.onDelete();
        }
    };

    return (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={isOpen}
          activator={activator}
          onOpen={openHandle}
          onClose={closeHandle}>
            <div className="DialogDelete text-center p-5">
                <h4>Are you sure you want to delete this entry?</h4>

                <p className="mb-0 font-size-lg">{description}</p>

                <p className="you-cannot mb-0 font-size-lg">You cannot undo this operation.</p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                  className="pt-4">
                    <Button
                      className="mx-1"
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpen(false)}>
                        <span>Cancel</span>
                    </Button>

                    <Button
                      className="mx-1 delete-button"
                      variant="outlined"
                      color="primary"
                      disabled={processing}
                      onClick={deleteHandle}>
                        <span>Delete</span>
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

DialogDelete.propTypes = {
    description: PropTypes.string,
    activator: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
};


export default DialogDelete;
