import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {Divider} from "@material-ui/core";
import {Check as CheckIcon} from "@material-ui/icons";

import {useORM} from "src/hooks";

import {
    ActionDelete,
    EntityList,
    CreateButton
} from "src/views/core";

import {
    DemandCategory,
    IDemandCategory
} from "src/store/models/DemandCategory";


const List = (props:any) => {
    const {
        rowActions
    } = props;

    const orm = useORM();

    return (
        <EntityList {...props}
          titleHeading="Demand"
          title="Demand category list"
          breadcrumbs={[
            {
                title: "Demand Category",
                to: `/${DemandCategory.modelName}`
            }
          ]}
          headers={[
            {
                name: "icon",
                title: "Icon",
                width: 100,
                render(record:IDemandCategory) {
                    const {
                        label,
                        icon
                    } = record;

                    return icon && (
                        <img
                          style={{
                            width: 55,
                            height: "auto"
                          }}
                          alt={label}
                          src={icon} />
                    );
                }
            },
            {
                name: "id",
                title: "ID",
                width: 350
            },
            {
                name: "is_suggested",
                title: "Is suggested",
                width: 150,
                render(record:IDemandCategory) {
                    return (
                        <React.Fragment>
                            {record.is_suggested && (
                                <CheckIcon />
                            )}
                        </React.Fragment>
                    );
                }
            },
            {
                name: "parentId",
                title: "Parent",
                render(record:IDemandCategory) {
                    const parent = orm.byId(DemandCategory.modelName, record.parentId);

                    return record.parentId && (
                        <React.Fragment>
                            <Link to={`/${DemandCategory.modelName}/${record.parentId}`}>
                                {parent ? parent.label : record.parentId}
                            </Link>
                        </React.Fragment>
                    );
                }
            },
            {
                name: "label",
                title: "Label"
            }
          ]}
          rowActions={[
            ...rowActions,
            <Divider />,
            <ActionDelete />
          ]}
          bottomActions={[
            <CreateButton />
          ]} />
    );
};

List.propTypes = {
    name: PropTypes.string
};


export default List;