import {
    useSelector as useReduxSelector,
    TypedUseSelectorHook
} from "react-redux";

import {State} from "src/store";


const useSelector:TypedUseSelectorHook<State> = useReduxSelector;


export {useSelector};