import React, {
    useContext
} from "react";


export const ResourceContext = React.createContext({});

export const useResourceContext = () => {
    return useContext(ResourceContext);
};

export const ResourceProvider = ResourceContext.Provider;
export const ResourceConsumer = ResourceContext.Consumer;
