import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetOne,
    IResponseGetList,
    IResponseCreateOne,
    IResponseUpdateOne,
    IResponseRemoveOne
} from "src/makes/Model";


interface ITileParameter {
    id:string;
    label:string;
    isSuggested:boolean;
    icon:string;
}

class TileParameter extends Model<ITileParameter> {
    static modelName = "tile-parameter";

    async getOne(id:any):IResponseGetOne<ITileParameter> {
        const res = await super._getOne(id);

        if(res.httpCode === 200) {
            const {
                response: {
                    label_translations: labels = {},
                    suggested,
                    files,
                    ...otherData
                }
            } = res;

            const [file] = files;

            const {
                links: {
                    sizes: {
                        original: {
                            view: icon = ""
                        } = {}
                    } = {}
                } = {}
            } = file || {}

            return {
                status: STATUS_SUCCESS,
                message: "",
                item: {
                    ...otherData,
                    labels: !Array.isArray(labels) ? labels : {},
                    isSuggested: suggested,
                    icon
                }
            };
        }

        const {
            response: {
                message
            }
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<ITileParameter> {
        const res = await this.dispatch("api/get", `/api/${this.accessType}/${this.modelName}/list`, {
            headers: {
                Authorization: `Bearer ${this.rootGetters.auth.token}`
            },
            query: query
        });

        if(typeof res.count === "number") {
            const {
                count: total,
                data: items
            } = res;

            return {
                status: STATUS_SUCCESS,
                message: "",
                total,
                items: items.map((item:any) => {
                    const {
                        title,
                        label = title,
                        suggested,
                        ...other
                    } = item;

                    return {
                        ...other,
                        label,
                        isSuggested: suggested
                    };
                })
            };
        }

        return {
            status: STATUS_ERROR
        };
    }

    async createOne(data:any):IResponseCreateOne<ITileParameter> {
        const {
            isSuggested,
            icon,
            ...other
        } = data;

        const res = await this.dispatch("api/post", `/api/${this.accessType}/${TileParameter.modelName}/create`, {
            headers: this.rootGetters.data.headers,
            data: {
                ...other,
                ...isSuggested ? {
                    suggested: isSuggested
                } : {},
                ...icon instanceof File ? {
                    image: icon
                } : {}
            }
        });

        if(res.httpCode === 200 || res.httpCode === 201) {
            const {
                response: {
                    id,
                    label,
                    file_image
                }
            } = res;

            return {
                status: STATUS_SUCCESS,
                message: "Tile parameter was created.",
                item: {
                    ...other,
                    id,
                    label,
                    isSuggested,
                    icon: file_image
                }
            };
        }

        const {
            response: {
                message
            }
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async updateOne(id:any, data:any):IResponseUpdateOne<ITileParameter> {
        const {
            isSuggested,
            icon,
            ...other
        } = data;

        const res = await this.dispatch("api/post", `/api/dashboard/${TileParameter.modelName}/update/${id}`, {
            headers: this.rootGetters.data.headers,
            data: {
                ...other,
                ...isSuggested ? {
                    suggested: isSuggested
                } : {},
                ...icon instanceof File ? {
                    image: icon
                } : {}
            }
        });

        if(res.httpCode === 200) {
            return {
                status: STATUS_SUCCESS,
                message: "Tile parameter was updated."
            };
        }

        const {
            response: {
                message
            }
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async removeOne(id:any):IResponseRemoveOne<ITileParameter> {
        const res = await this.dispatch("api/post", `/api/dashboard/${TileParameter.modelName}/delete/${id}`, {
            headers: this.rootGetters.data.headers
        });

        if(res.httpCode === 200) {
            return {
                status: STATUS_SUCCESS,
                message: "Tile parameter was deleted."
            };
        }

        return {
            status: STATUS_ERROR
        };
    }
}


export type {ITileParameter};

export {TileParameter};