import React from "react";
import {connect} from "react-redux";
import {
    IconButton,
    Popper,
    Paper,
    Grow,
    ClickAwayListener,
    List
} from "@material-ui/core";
import {
    AccountCircle as AccountCircleIcon
} from "@material-ui/icons";

import store from "../../../../../../../store";


class HeaderMenu extends React.Component<any, any> {
    anchorRef:any;

    constructor(props:any) {
        super(props);

        this.state = {
            selectedIndex: -1,
            open: false
        };

        this.anchorRef = React.createRef();
    }

    onClick(evt:any) {
        this.toggleMenu();
    }

    toggleMenu() {
        this.setState({
            open: !this.state.open
        });
    }

    openMenu() {
        this.setState({
            open: true
        });
    }

    closeMenu(event:any) {
        if(this.anchorRef.current && this.anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        this.setState({
            open: false
        });
    }

    render() {
        const {
            children
        } = this.props;

        const {
            open
        } = this.state;

        return (
            <div ref={this.anchorRef}>
                <IconButton
                  size="medium"
                  onClick={(evt) => this.onClick(evt)}>
                    <AccountCircleIcon
                      fontSize="large" />
                </IconButton>

                <Popper
                  open={open}
                  anchorEl={this.anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal>
                    {({TransitionProps, placement}) => {
                        return (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                              }}>
                                <Paper>
                                    <ClickAwayListener onClickAway={(event) => this.closeMenu(event)}>
                                        {/* <MenuList>
                                            {options.map((option, index) => (
                                                <MenuItem
                                                  key={option}
                                                  selected={index === selectedIndex}
                                                  onClick={(event) => this.onClickItem(event, index)}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>*/}

                                        <List>
                                            {children}
                                        </List>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        );
                    }}
                </Popper>
            </div>
        );
    }
}


const putState = () => {
    return {
        isLoggedIn: store.auth.getters.isLoggedIn
    };
};

export default connect(putState)(HeaderMenu);