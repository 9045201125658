import {Model} from "src/makes/Model";

/* eslint-disable */


export interface IArea {
  id: string;
  type: string;
  quadKey: string;
  loaded: boolean;
}


class Area extends Model<IArea> {
  static modelName: string = 'area';
  static filters = {
    type: (area: IArea, type: string) => {
      return area.type === type;
    },
    quadKey: (area: IArea, quadKey: string) => {
      return area.quadKey === quadKey;
    }
  };

  static prepare(item: any, method: string) {
    return {
      id: item.quadKey + '-' + item.type,
      ...item
    };
  }
}


export default Area;
