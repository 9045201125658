import React from "react";
import PropTypes from "prop-types";
import {Field} from "redux-form";

import {
    Checkbox
} from "../../rf-wrappers";


const CheckboxField = (props) => {
    const {
        ...rest
    } = props;

    return (
        <Field component={Checkbox}
          {...rest} />
    );
}

CheckboxField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired
};


export default CheckboxField;
