import {Model} from "src/makes/Model";
import {IPoint} from "src/types";

/* eslint-disable */


export interface ICityCenter {
  id: string;
  point: IPoint;
  total: number;
}

class CityCenter extends Model<ICityCenter> {
  static modelName = 'city-center';
}


export default CityCenter;
