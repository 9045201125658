import React, {
    useState,
    useEffect
} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    Select,
    MenuItem,
    CircularProgress
} from "@material-ui/core";

import InputMaskReload from "../InputMaskReload";

import store from "../../../store";


const InputPhone = (props) => {
    const {
        label,
        mask,
        message,
        error,
        find,
        search,
        value,
        onChange,
        onFocus,
        onBlur,
        ...rest
    } = props;

    const strictValue = (value || "").replace(new RegExp("\\s", "g"), "");
    const [id] = useState("input-phone-id-" + InputPhone.COUNT++);
    const [prefix, setPrefix] = useState("");
    const [phone, setPhone] = useState("");
    const prefixes = find("nomenclature", {
        code: "phone_prefix"
    });

    useEffect(() => {
        search("nomenclature", {
            code: "phone_prefix"
        }).then(() => {
            prepareValue();
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, prefixes.length]);

    useEffect(() => {
        prepareValue();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const buildMask = () => {
        if(prefix) {
            let len = 12 - prefix.length;

            switch(len) {
                case 8:
                    return "99 999 999";

                case 9:
                    return "999 999 999";

                case 10:
                    return "999 999 99 99";

                case 11:
                    return "999 999 999 99";

                default:
                    return "9".repeat(len);
            }
        }
        else {
            return "09 999 99 99 999";
        }
    };

    const prepareValue = () => {
        const prefixes = find("nomenclature", {
            code: "phone_prefix"
        });

        if(prefixes.length > 0) {
            if(new RegExp("^\\+[0-9]+$").test(strictValue)) {
                for(let i in prefixes) {
                    let prefixData = prefixes[i];

                    if(strictValue.indexOf(prefixData.name) === 0) {
                        let newPrefix = strictValue.slice(0, prefixData.name.length);
                        let newPhone = strictValue.slice(prefixData.name.length);

                        setPrefix(newPrefix);
                        setPhone(newPhone);
                    }
                }
            }
            else {
                setPrefix("");
                setPhone(value);
            }
        }
        else {
            setPrefix("");
            setPhone(value);
        }
    };

    return (
        <FormControl
          className="w-100"
          error={!!error}>
            <InputLabel
              id={id + "-label"}
              htmlFor={id}>
                {label}
            </InputLabel>

            {
                prefixes.length > 0 ? (
                    <InputMaskReload
                      mask={buildMask()}
                      maskChar={""}
                      formatChars={{
                        "0": "[0-9+]",
                        "9": "[0-9]",
                        "a": "[A-Za-z]",
                        "*": "[A-Za-z0-9]"
                      }}
                      value={phone}
                      onChange={(e) => {
                        let newPhone = e.target.value || "";

                        setPhone(newPhone);

                        if(onChange) {
                            onChange((prefix ? prefix + " " : "") + newPhone);
                        }
                      }}
                      {...rest}>
                        {(props) => {
                            return (
                                <Input
                                  id={id}
                                  startAdornment={
                                    <InputAdornment
                                      style={{width: "85px"}}
                                      position="start">
                                        <Select
                                          className="w-100"
                                          value={prefix}
                                          displayEmpty
                                          onChange={(e) => {
                                            let newPrefix = e.target.value || "";

                                            setPrefix(newPrefix);

                                            if(onChange) {
                                                let newValue = (newPrefix + " " + phone).replaceAll(" ", "").slice(0, 12);

                                                onChange(newValue);
                                            }
                                          }}>
                                            <MenuItem value="">-</MenuItem>

                                            {
                                                prefixes.map((prefix, index) => {
                                                    return (
                                                        <MenuItem key={index}
                                                          value={prefix.name}>
                                                            {prefix.name} ({prefix.label})
                                                        </MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </InputAdornment>
                                  }
                                  {...props} />
                            );
                        }}
                    </InputMaskReload>
                ) : (
                    <CircularProgress size={20} />
                )
            }
        </FormControl>
    );
};

InputPhone.COUNT = 0;

InputPhone.propTypes = {
    label: PropTypes.string,
    message: PropTypes.string,
    error: PropTypes.string
};


const putState = () => {
    return {
        find: store.orm.getters.find
    };
};

const putActions = () => {
    return {
        search: store.orm.search
    };
};

export default connect(putState, putActions)(InputPhone);