import {AddCircleOutline as AddCircleOutlineIcon} from "@material-ui/icons";

import {
    EntityCreate,
    EntityEdit,
    ResourceProps
} from "src/views/core";

import List from "./List";
import Form from "./Form";

import {SuggestCategory} from "src/store/models/SuggestCategory";


export const suggestCategoryProps:ResourceProps = {
    name: SuggestCategory.modelName,
    title: "Suggestions",
    // @ts-ignore
    icon: AddCircleOutlineIcon,
    list: List,
    edit: EntityEdit,
    form: Form,
    create: EntityCreate
};
