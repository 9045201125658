import React from "react";
import {Link} from "react-router-dom";
import {
    withStyles,
    createStyles,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@material-ui/core";

import * as resources from "src/views/resources";
import "./index.scss";


type Props = {
    onCloseDrawer:() => void;
};

const DashboardList = withStyles(() => {
    return createStyles({
        root: {
            padding: 0,
        }
    });
})(List);


const DashboardMenu:React.FC<Props> = (props:Props) => {
    const {
        onCloseDrawer
    } = props;

    return (
        <Grid className="DashboardMenu">
            <DashboardList>
                {Object.values(resources).map((item, index) => {
                    const {
                        name,
                        basePath = `/${name}`,
                        // @ts-ignore
                        icon: Icon
                    } = item;

                    return (
                        <ListItem key={index}
                          component={Link}
                          button
                          className="mobile-list-item"
                          selected={window.location.pathname.indexOf(basePath) === 0}
                          to={basePath}
                          onClick={onCloseDrawer}>
                            {Icon && (
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                            )}

                            <ListItemText
                              className="dashboard-sidebar__title"
                              primary={item.title} />
                        </ListItem>
                    );
                })}
            </DashboardList>
        </Grid>
    );
};


export default DashboardMenu;