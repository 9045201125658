import React from 'react';
import PropTypes from 'prop-types';
import { useParams, generatePath, Link } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResourceContext } from '../Resource/hooks';
import { useGridTableRowContext } from '../../blocks/GridTable/context';

const EditAction = props => {
  const { path, params } = props;

  const resourceContext = useResourceContext();
  const {
    record: { id },
  } = useGridTableRowContext();

  const editPath = path || (resourceContext ? resourceContext.editPath : '/');
  const editParams = {
    ...useParams(),
    ...(params || {}),
  };

  return (
    <ListItem
      component={Link}
      button
      to={generatePath(editPath, {
        ...editParams,
        id: id,
      })}
    >
      <div className='nav-link-icon opacity-6'>
        <FontAwesomeIcon icon={['fas', 'pen']} />
      </div>

      <span className='ml-4'>Edit</span>
    </ListItem>
  );
};

EditAction.propTypes = {
  path: PropTypes.string,
};

export default EditAction;
