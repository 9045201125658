import React, {useState, useEffect, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {Grid, CircularProgress} from "@material-ui/core";

import {useShowMessages} from "src/hooks";

import {ResourceEditProvider} from "./context";

import store from "src/store";


type Props = any;

const ResourceEdit:React.FC<Props> = (props:Props) => {
    const {
        component: Component,
        ...editProps
    } = props;

    const {
        name,
        id
    } = editProps;

    const [loaded, setLoaded] = useState(false);
    const params = useParams();
    const showMessages = useShowMessages();
    const entity = props.byId(name, id);

    useEffect(() => {
        if(!entity) {
            load();
        }
        else {
            setLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = async () => {
        if(loaded) {
            setLoaded(false);
        }

        const res = await props.fetch(name, id, params);

        showMessages(res);

        if(res && res.status === "OK") {
            setLoaded(true);
        }
    };

    return (
        <Fragment>
            {loaded ? (
                <ResourceEditProvider
                  value={{
                    name: name,
                    id: id,
                    record: entity,
                    setProcessing: v => setLoaded(!v),
                    reload: load
                  }}>
                    {Component && <Component {...editProps} />}
                  </ResourceEditProvider>
            ) : (
                <Grid container justifyContent='center' alignItems='center'>
                    <Grid item className='p-5'>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
        </Fragment>
    );
};

ResourceEdit.propTypes = {
    component: PropTypes.elementType.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};


const putState = () => {
    return {
        byId: store.orm.getters.byId,
    };
};

const putActions = () => {
    return {
        fetch: store.orm.fetch,
    };
};

export default connect(putState, putActions)(ResourceEdit);
