import React, {useState, useEffect, ChangeEvent, MouseEvent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as QueryString from "querystring";

import {STATUS_SUCCESS} from "src/makes/Model";
import {buildQuery} from "src/tools";
import {useShowMessages} from "src/hooks";
import {PageTitle, GridTable} from "src/views/blocks";

import {EntityListProvider} from "./context";

import store, {RawData} from "src/store";


type Props = {
    name: string;
    progress?: boolean;
    pagination?: boolean;
    filter?: any;
    title?: string;
    headers?: any[];
    rowActions?: any[]
    headActions?: any[];
    bottomActions?: any[];
    showTitle?: boolean;
    titleHeading?: string;
    titleDescription?: string;
    breadcrumbs?: any[];
    isCheckboxActions?: boolean;
};

const EntityList:React.FC<Props> = (props:Props) => {
    const {
        name,
        pagination = false,
        filter = {},
        title,
        headers,
        rowActions,
        headActions,
        bottomActions,
        showTitle = true,
        titleHeading,
        titleDescription,
        breadcrumbs,
        isCheckboxActions,
    } = props;

    const showMessages = useShowMessages();
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(10);
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState("id");
    const [orderWay, setOrderWay] = useState("desc");
    const [loading, setLoading] = useState(false);
    const [progress, setProcessing] = useState(props.progress || false);
    // @ts-expect-error
    const list = props.find(name, filter, ...pagination ? [count, page] : []);
    // @ts-expect-error
    const {total = 0} = props.getMeta(name, filter);

    const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = list.map((item: any) => item.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: MouseEvent<unknown>, name: string) => {
        // @ts-ignore
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        // @ts-ignore
        setSelected(newSelected);
    };

    const handleDelete = async () => {
        setLoading(true);
        const res = await store.orm.removeMany(name, selected)

        if (res.status === STATUS_SUCCESS) {
            setLoading(false);
            setSelected([])
            showMessages(res);
            fetchData();
        }
        else {
            showMessages(res);
        }
    }


    useEffect(() => {
        fetchData();

        // eslint-disable-next-line
    }, [name, count, page, orderBy, orderWay, buildQuery(filter)]);

    useEffect(() => {
        // @ts-expect-error
        setProcessing(props.progress);
    }, [props.progress]);

    const fetchData = async () => {
        setLoading(true);

        const res = await store.orm.search(name, filter, ...pagination ? [count, page] : []);

        if(res.status === STATUS_SUCCESS) {
            setLoading(false);
        }
        else {
            showMessages(res);
        }
    };

    return (
        <EntityListProvider
          value={{
            name: name,
            setProcessing: setProcessing,
            reload: fetchData
          }}>
            {showTitle && (
                <PageTitle
                  titleHeading={titleHeading}
                  titleDescription={titleDescription}
                  breadcrumbs={breadcrumbs} />
            )}

            <GridTable
              loading={loading}
              title={title}
              headers={headers}
              data={list}
              {...(pagination ? {
                pagination: pagination,
                page: page,
                perPage: count,
                limit: total,
                orderBy: orderBy,
                orderWay: orderWay
              } : {
                pagination: pagination,
                page: 0,
                limit: 0
              })}
              progress={progress}
              selected={selected}
              rowActions={rowActions}
              headActions={headActions}
              bottomActions={bottomActions}
              isCheckboxActions={isCheckboxActions}
              onClickRow={handleClick}
              onSelectAllClick={handleSelectAllClick}
              onChangePage={page => setPage(page)}
              onDelete={handleDelete}
              onChangePerPage={perPage => {
                setPage(0);
                setCount(perPage);
              }}
              onChangeOrder={(orderBy, orderWay) => {
                setPage(0);
                setOrderBy(orderBy);
                setOrderWay(orderWay);
              }} />
        </EntityListProvider>
    );
};

EntityList.propTypes = {
    progress: PropTypes.bool,
    title: PropTypes.string,
    name: PropTypes.string.isRequired,
    filter: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    headers: PropTypes.array.isRequired,
    rowActions: PropTypes.array,
    headActions: PropTypes.array,
    bottomActions: PropTypes.array,
    showTitle: PropTypes.bool,
    titleHeading: PropTypes.string,
    titleDescription: PropTypes.string,
    breadcrumbs: PropTypes.array,
    isCheckboxActions: PropTypes.bool,
};


const putState = () => {
    return {
        find: store.orm.getters.find,
        getMeta: store.orm.getters.getMeta
    };
};

export default connect(putState)(EntityList);
