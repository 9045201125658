import React, {useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {EntityList} from "src/views/core";

import store from "src/store";
import {User} from "src/store/models/User";
import {Input} from "src/views/inputs";


const List = (props:any) => {
    const [searchEmail, setSearchEmail] = useState('');

    return (
        <EntityList {...props}
          pagination
          titleHeading="Users"
          title="Users list"
          headActions={[
              <Input
                  value={searchEmail}
                  onChange={event => setSearchEmail(event.target.value)}
                  label="Email"
              />
          ]}
          breadcrumbs={[
            {
                title: "Users",
                to: `/${User.modelName}`
            }
          ]}
          filter={searchEmail && searchEmail.length > 2 ? {
             email: searchEmail
          } : ''}
          headers={[
            {
                name: "display_name",
                title: "Name",
                width: 100
            },
            {
                name: "email",
                title: "Email",
                width: 100
            },
            {
                name: "role",
                title: "User role",
                width: 100
            }
          ]} />
    );
};

List.propTypes = {
    name: PropTypes.string,
    search: PropTypes.func.isRequired
};


const putActions = () => ({
  search: store.orm.search
});

export default connect(null, putActions)(List);
