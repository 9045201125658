import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

import {
    EntityEdit,
    ResourceProps
} from "src/views/core";

import List from "./List";
import Form from "./Form";

import {User} from "src/store/models/User";


export const userProps:ResourceProps = {
    name: User.modelName,
    title: "Users",
    // path: `/${User.modelName}`,
    // @ts-ignore
    icon: PeopleAltIcon,
    list: List,
    edit: EntityEdit,
    form: Form
};