import React from "react";
import {
    makeStyles,
    Theme,
    Hidden,
    Grid
} from "@material-ui/core";

import {
    DashboardHeader,
    DashboardSidebar,
    DashboardDrawer
} from "./blocks";

import "./index.scss";


const DashboardLayout:React.FC = (props) => {
    const {
        children
    } = props;

    const classes = useStyles(props);

    return (
        <Grid container
          className={[classes.root, "dashboard-layout"].join(" ")}
          alignContent="stretch"
          direction="column">
            <Grid item
              className={[classes.header, "dashboard-layout__header"].join(" ")}>
                <DashboardHeader />
            </Grid>

            <Grid container item
              className={[classes.wrapper, "dashboard-layout__wrapper"].join(" ")}
              alignContent="stretch"
              wrap="nowrap">
                <Hidden only={["xs", "sm", "md", "lg"]}>
                    <Grid item
                      className={[classes.sidebar, "dashboard-layout__sidebar"].join(" ")}>
                        <DashboardSidebar />
                    </Grid>
                </Hidden>

                <Grid item
                  className={[classes.content, "dashboard-layout__content"].join(" ")}>
                    {children}
                </Grid>
            </Grid>

            <DashboardDrawer />
        </Grid>
    );
};


const useStyles = makeStyles((theme:Theme) => {
    return {
        root: {
            [theme.breakpoints.up("lg")]: {
                height: "100vh"
            }
        },
        header: {
            [theme.breakpoints.up("lg")]: {
                flexBasis: 81,
                height: 81
            }
        },
        wrapper: {
            [theme.breakpoints.up("lg")]: {
                flexGrow: 1,
                height: "calc(100vh - 81px)"
            }
        },
        sidebar: {
            width: 250,
            flexBasis: 250,
            paddingTop: 23
        },
        content: {
            [theme.breakpoints.up("lg")]: {
                paddingLeft: 17,
                flexBasis: "calc(100vw - 250px)",
                width: "calc(100vw - 250px)"
            }
        }
    };
});

export default DashboardLayout;