import React from "react";
import {
    SvgIcon,
    SvgIconProps
} from "@material-ui/core";


const SadEmotion:React.FC<SvgIconProps> = (props:SvgIconProps) => {
    const {
        viewBox,
        ...rest
    } = props;

    return (
        <SvgIcon
          {...rest}
          viewBox="0 0 50 50">
            <path
              d="M27,2A25,25,0,1,0,52,27,25,25,0,0,0,27,2M14.5,20.75A3.845,3.845,0,0,1,18.25,17a3.75,3.75,0,0,1,0,7.5,3.845,3.845,0,0,1-3.75-3.75M33.925,40.075C32.8,38.25,30.125,37,27,37s-5.8,1.25-6.925,3.075l-3.55-3.55A13.566,13.566,0,0,1,27,32a13.566,13.566,0,0,1,10.475,4.525l-3.55,3.55M35.75,24.5a3.75,3.75,0,1,1,3.75-3.75A3.845,3.845,0,0,1,35.75,24.5Z"
              transform="translate(-2 -2)" />
        </SvgIcon>
    );
};


export default SadEmotion;