import {Room as RoomIcon} from "@material-ui/icons";

import {
    EntityCreate,
    EntityEdit,
    ResourceProps
} from "src/views/core";

import List from "./List";
import Form from "./Form";

import {TileParameter} from "src/store/models/TileParameter";


export const tileParameterProps:ResourceProps = {
    name: TileParameter.modelName,
    title: "Ratings",
    // @ts-ignore
    icon: RoomIcon,
    list: List,
    form: Form,
    edit: EntityEdit,
    create: EntityCreate
};