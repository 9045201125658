import React from "react";


// TODO: add content for page

const Error404:React.FC = () => {
    return (
        <React.Fragment>
            Error 404
        </React.Fragment>
    );
};


export default Error404;