import React from "react";
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    IconButton
} from "@material-ui/core";
import {
    PlayArrow as PlayArrowIcon
} from "@material-ui/icons";


interface Props extends WithStyles {
    [key:string]:any;
}

class PageBackButton extends React.Component<Props, any> {
    render() {
        const {
            classes,
            ...rest
        } = this.props;

        return (
            <IconButton
              {...rest}
              classes={{
                root: classes.buttonRoot,
                label: classes.buttonLabel
              }}>
                <PlayArrowIcon
                  fontSize="large"
                  color="primary" />
            </IconButton>
        );
    }
}


const styles = (theme:Theme) => createStyles({
    buttonRoot: {
        padding: "0"
    },
    buttonLabel: {
        transform: "rotate(180deg)"
    }
});

export default withStyles(styles)(PageBackButton);