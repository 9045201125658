import React, {
    useRef,
    useState,
    useEffect,
    Fragment
} from "react";
import PropTypes from "prop-types";
import * as lodash from "lodash";
import {
    FormControl,
    Input,
    InputLabel,
    FormHelperText,
    CircularProgress
} from "@material-ui/core";
import {
    Autocomplete,
    createFilterOptions
} from "@material-ui/lab";


const filter = createFilterOptions();

const AutoInput = (props) => {
    const {
        raw = false,
        error,
        label,
        value,
        inputValue: inputV,
        message,
        name,
        loading,
        options = [],
        onChange,
        ...rest
    } = props;

    const getOptionLabel = (optionOrValue) => {
        if(typeof optionOrValue === "string") {
            let option = options.find((option) => {
                return option.value === optionOrValue;
            });

            if(option) {
                return option.title;
            }
            else if(raw) {
                return optionOrValue;
            }
        }

        if(optionOrValue && typeof optionOrValue === "object") {
            return optionOrValue.title;
        }

        return "";
    };

    const ref = useRef(null);
    const [id] = useState(rest.id || "auto-input-id-" + AutoInput.COUNT++);
    const [inputValue, setInputValue] = useState(getOptionLabel(value) || inputV || value);
    const [prev, setPrev] = useState([]);

    const prepareInputValue = () => {
        let label = getOptionLabel(value);

        if(label) {
            setInputValue(label);
        }
    };

    useEffect(() => {
        prepareInputValue();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        if(!lodash.isEqual(options, prev)) {
            setPrev(options);

            prepareInputValue();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    return (
        <FormControl
          className="w-100"
          error={Boolean(error)}>
            <Autocomplete ref={ref}
              {...rest}
              id={id}
              loading={loading}
              options={prev}
              value={value}
              inputValue={inputValue}
              onChange={onChange}
              filterOptions={(options, state) => {
                const filtered = filter(options, state);
                const {
                    inputValue
                } = state;

                if(raw && !loading && inputValue !== "") {
                    filtered.push({
                        title: inputValue,
                        value: inputValue
                    });
                }

                return filtered;
              }}
              getOptionSelected={(option, value) => {
                return option.value === value;
              }}
              getOptionLabel={getOptionLabel}
              onInputChange={(e, newInputValue, reason) => {
                setInputValue(newInputValue);

                if(props.onInputChange) {
                    props.onInputChange(e, newInputValue, reason);
                }
              }}
              renderInput={(props) => {
                const {
                    id,
                    disabled,
                    InputProps: {
                        className: ignore1,
                        ...InputProps
                    },
                    inputProps: {
                        className: ignore2,
                        ...inputProps
                    },
                    InputLabelProps
                } = props;

                return (
                    <Fragment>
                        <InputLabel
                          id={id + "-label"}
                          htmlFor={id}
                          {...InputLabelProps}>
                            {label}
                        </InputLabel>

                        <Input
                          id={id}
                          className="w-100"
                          disabled={disabled}
                          inputProps={inputProps}
                          name={name}
                          {...InputProps}
                          endAdornment={
                            <Fragment>
                                {
                                    loading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20} />
                                    ) : null
                                }

                                {InputProps.endAdornment}
                            </Fragment>
                          } />

                        <FormHelperText
                          id={id + "-text"}>
                            {error || message}
                        </FormHelperText>
                    </Fragment>
                );
              }} />
        </FormControl>
    )
};

AutoInput.COUNT = 0;

AutoInput.propTypes = {
    label: PropTypes.string,
    message: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};


export default AutoInput;
