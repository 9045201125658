import FontDownloadIcon from "@material-ui/icons/FontDownload";

import {ResourceProps} from "src/views/core";

import List from "./List";

import {RawData} from "src/store/models/RawData";


export const rawDataProps:ResourceProps = {
    name: RawData.modelName,
    title: "Raw data",
    // @ts-ignore
    icon: FontDownloadIcon,
    // basePath: `/${RawData.modelName}`,
    list: List
};
