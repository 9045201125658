import React from "react";
import {compose} from "redux";
import {
    Avatar,
    AvatarProps,
    createStyles,
    withStyles,
    WithStyles,
    StyledComponentProps
} from "@material-ui/core";


interface LogoProps extends AvatarProps {
    width?:number;
    height?:number;
    size?:"small"|"medium"|"large";
}

const Logo:React.FC<LogoProps & WithStyles> = (props:LogoProps & WithStyles) => {
    const {
        size,
        width,
        height,
        classes,
        ...rest
    } = props;

    let className = "";

    if(size) {
        className = "size" + size.substr(0, 1).toUpperCase() + size.substr(1);
    }

    return (
        <Avatar {...rest}
          className={size ? classes[className] : undefined}
          classes={{
            root: size ? classes[className] : undefined
          }} />
    );
};


const styles = () => createStyles({
    sizeSmall: {
        width: 30,
        height: 30
    },
    sizeMedium: {
        width: 40,
        height: 40
    },
    sizeLarge: {
        width: 57,
        height: 57
    }
});

export default compose<React.ComponentType<LogoProps & StyledComponentProps>>(
    withStyles(styles)
)(Logo);