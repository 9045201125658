import QS from "query-string";

/* eslint-disable */


interface Options {
  encode?: boolean;
  _encode?: boolean;
}


class QueryString {
  static stringify(query: any = {}, options: Options = {}): string {
    const {
      encode = true,
      _encode = true
    } = options;

    return Object.keys(query).map((key) => {
      let value = query[key];
      let _key = _encode ? encodeURIComponent(key) : key;

      switch (typeof value) {
        case 'object':
          if (Array.isArray(value)) {
            return QueryString.stringify(
              value.reduce((q, v, index) => {
                q[_key + '[' + index + ']'] = v;

                return q;
              }, {}),
              {
                ...options,
                _encode: false
              }
            );
          } else if (value) {
            return QueryString.stringify(
              Object.keys(value).reduce((q: any, k: string) => {
                q[_key + '[' + encodeURIComponent(k) + ']'] = value[k];

                return q;
              }, {}),
              {
                ...options,
                _encode: false
              }
            );
          }

          return '';

        default:
          if (typeof value !== 'undefined') {
            if (encode) {
              return _key + '=' + encodeURIComponent(value);
            } else {
              return _key + '=' + value;
            }
          }

          return '';
      }
    }).filter((v) => v).join('&');
  }

  static parse(query: string, options?: any): any {
    const {
      parseNumbers = true
    } = options || {};

    return QS.parse(query, {
      arrayFormat: 'index',
      parseNumbers: parseNumbers,
      parseBooleans: true
    });
  }
}


export {QueryString};
