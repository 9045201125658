import * as fns from "date-fns";
import * as lodash from "lodash";

import Model from "src/makes/Model";
import { calcRate } from "src/tools";

/* eslint-disable */


export interface ISuggest {
  id: string;
  userId: string;
  categoryId: string;
  top5Ids: string[];
  address: string;
  quadKeys: string[];
  title: string;
  description: string;
  user: any;
  image: any;
  votes: number;
  votesPositive: number;
  votesNeutral: number;
  votesNegative: number;
  votesLocal: number;
  votesNonLocal: number;
  votesLocalPositive: number;
  votesLocalNeutral: number;
  votesLocalNegative: number;
  commentsTotal: number;
  rate: number;
  rateLocal: number;
  rateNonLocal: number;
  rateSurrounding: number;
  updateDays: number;
  date: string;
  updateDate: string;
}

export class Suggest extends Model<ISuggest> {
  static modelName:string = "suggest";
  static queryCount:string = "count";
  static queryPage:string = "page";

  static filters = {
    quadKey: (suggest:ISuggest, quadKeys:string | string[]) => {
      return suggest.quadKeys.reduce((includes: boolean, quadKey: string) => {
        if (Array.isArray(quadKeys)) {
          return includes || quadKeys.reduce((includes: boolean, qk: string) => {
            return includes || quadKey.indexOf(qk) === 0;
          }, false);
        } else {
          return includes || quadKey.indexOf(quadKeys) === 0;
        }
      }, false);
    }
  };

  static prepare(data:any, method:string):ISuggest {
    const {
      image: images,
      votesTotal: votes = 0,
      address,
      ...other
    } = data;

    const {
      votesPositive = 0,
      votesNeutral = 0,
      votesNegative = 0,
      votesLocalPositive = 0,
      votesLocalNeutral = 0,
      votesLocalNegative = 0,
      updateDate
    } = data;

    const votesLocal = votesLocalPositive + votesLocalNeutral + votesLocalNegative;
    let update = fns.parse(updateDate, 'yyyy-MM-dd', new Date());

    let imageSrc = lodash.get(images, '[0].links.sizes.default.view', null);

    return {
      ...other,
      address: address || 'No address',
      votes: votes,
      votesLocal: votesLocal,
      votesNonLocal: votes - votesLocal,
      rate: calcRate(votesPositive, votesNeutral, votesNegative) || 0,
      image: imageSrc ? { src: imageSrc } : null,
      updateDays: fns.isValid(update) ? fns.differenceInCalendarDays(new Date(), update) : 0
    };
  }
}


export default Suggest;
