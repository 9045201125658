import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Hidden
} from "@material-ui/core";
import {
    Menu as MenuIcon,
    PersonOutline as PersonOutlineIcon
} from "@material-ui/icons";

import Logo from "src/views/blocks/Logo";

import {Menu} from "./blocks";

import {ROUTES} from "src/router";
import store from "src/store";


interface Props {}

interface RState {
    isLoggedIn:boolean;
    isDrawerOpen:boolean;
}

interface RActions {
    setDrawerOpen:(isDrawerOpen:boolean) => void;
}

interface CProps extends Props, RState, RActions {}

class DashboardHeader extends React.Component<CProps, any> {
    render() {
        const {isLoggedIn} = this.props;

        return (
            <AppBar className='header' position='fixed'>
                <Toolbar className='header__toolbar'>
                    <Hidden only={['xl']}>
                        <IconButton onClick={() => this.props.setDrawerOpen(true)}>
                            <MenuIcon />
                        </IconButton>
                    </Hidden>

                    <Logo src='/logo.svg' size='large' />

                    <Typography className='header__title' color='primary' variant='h2' to={ROUTES.main} component={Link}>
                        CityChange
                    </Typography>

                    <span className='header__space' />

                    <Menu>
                        {!isLoggedIn ? (
                            <ListItem component={Link} button to='/login'>
                                <ListItemIcon style={{ justifyContent: 'center' }}>
                                    <PersonOutlineIcon />
                                </ListItemIcon>

                                <ListItemText primary={'Login'} />
                            </ListItem>
                        ) : (
                            <ListItem component={Link} button to='/logout'>
                                <ListItemText primary={'Logout'} />
                            </ListItem>
                        )}
                    </Menu>
                </Toolbar>
            </AppBar>
        );
    }
}

const putState = (state:any):RState => {
    return {
        isDrawerOpen: state.ui.isDrawerOpen,
        isLoggedIn: store.auth.getters.isLoggedIn
    };
};

const putActions = ():RActions => {
    return {
        setDrawerOpen: store.ui.setDrawerOpen
    };
};

export default compose<React.ComponentType<Props>>(connect(putState, putActions))(DashboardHeader);