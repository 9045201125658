import React from "react";
import PropTypes from "prop-types";

import {
    Checkbox
} from "../../inputs";


const CheckboxWrapper = (props) => {
    const {
        meta,
        input,
        ...rest
    } = props;

    return (
        <Checkbox
          {...rest}
          {...input} />
    );
};

CheckboxWrapper.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    label: PropTypes.string
};


export default CheckboxWrapper;