import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStyles, withStyles, Drawer } from '@material-ui/core';
import DashboardMenu from './../DashboardMenu';
import store from '../../../../../store';

interface Props {}

interface RState {
  isDrawerOpen:boolean;
}

interface RActions {
  setDrawerOpen:(isDrawerOpen:boolean) => void;
}

interface CProps extends Props, RState, RActions {}

class DashboardDrawer extends React.Component<CProps, any> {
  private onCloseDrawer = () => {
    this.props.setDrawerOpen(false);
  };

  render() {
    const { isDrawerOpen } = this.props;

    return (
      <Drawer anchor='left' open={isDrawerOpen} onClose={() => this.props.setDrawerOpen(false)}>
        <DashboardMenu onCloseDrawer={this.onCloseDrawer} />
      </Drawer>
    );
  }
}

const styles = () => createStyles({});

const putState = (state:any) => {
  return {
    isDrawerOpen: state.ui.isDrawerOpen,
  };
};

const putActions = () => {
  return {
    setDrawerOpen: store.ui.setDrawerOpen,
  };
};

export default compose<React.ComponentType<Props>>(withStyles(styles), connect(putState, putActions))(DashboardDrawer);
