import * as fns from "date-fns";

import {IPoint, IBounds} from "src/types";

import {QueryString} from "src/makes/QueryString";
import {Bounds} from "src/makes/Bounds";
import {QuadTile} from "src/makes/QuadTile";


export {buildQuery} from "./buildQuery";

export const parseJWT = (token:string) => {
    try {
        const [, base64Url] = token.split(".") || [];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

        const jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));

        return JSON.parse(jsonPayload);
    }
    catch(err) {
        return {};
    }
};

export const isRequired = (value:any) => {
    return value ? undefined : "Mandatory field";
};

export const isEmail = (email:string) => {
    if(email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return "Invalid email address";
    }

    return undefined;
};

export const addLat = (point:IPoint, distance:number):IPoint => {
    const R = 6378.137;
    const cof = 1 / ((Math.PI / 180) * R) / 1000;

    return {
        lat: point.lat + distance * cof,
        lng: point.lng
    };
};

export const addLng = (point:IPoint, distance:number):IPoint => {
    const R = 6378.137;
    const cof = 1 / ((Math.PI / 180) * R) / 1000;

    return {
        lat: point.lat,
        lng: point.lng + (distance * cof) / Math.cos((point.lat * Math.PI) / 180)
    };
};

export const addLatLng = (point:IPoint, distanceLat:number, distanceLng:number):IPoint => {
    return addLat(addLng(point, distanceLng), distanceLat);
};

export const measure = (point1:IPoint, point2:IPoint):number => {
    const {lat: lat1, lng: lon1} = point1;
    const {lat: lat2, lng: lon2} = point2;

    const R = 6378.137;
    const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;

    return d * 1000;
};

export const toHtmlContent = (text?:string) => {
    return "<p>" + (text || "").split("\n").join("</p><p>") + "</p>";
};

export const dateConvert = (date:string, fromFormat:string | string[], toFormat:string) => {
    let formats = Array.isArray(fromFormat) ? fromFormat : [fromFormat];

    for(let i in formats) {
        let value = fns.parse(date, formats[i], new Date());

        if(fns.isValid(value)) {
          return fns.format(value, toFormat);
        }
    }

    return "[Invalid format '" + date + "' '" + fromFormat + "']";
};

export const getRestProps = (props:any, keys:string[]):any => {
    return Object.keys(props).reduce((rest:any, key:string) => {
        if(keys.includes(key)) {
            rest[key] = props[key];
        }

        return rest;
    }, {});
};

export const rateToColor = (rate:number):string => {
    const colors = [
        '#FF0000',
        '#FF3333',
        '#FF6666',
        '#FF9999',
        '#FFCCCC',
        '#CFFBD2',
        '#9CF2A3',
        '#71E476',
        '#4AD04C',
        '#2CB829'
    ];

    let rateColor = '';

    let colorIndex = Math.floor(rate / 10);

    if(colors[colorIndex]) {
        rateColor = colors[colorIndex];
    }
    else if(colors[colorIndex - 1]) {
        rateColor = colors[colorIndex - 1];
    }

    return rateColor;
};

export const calcRate = (positive:number, neutral:number, negative:number, round = true):number => {
    let total = positive + neutral + negative;

    if(total > 0) {
        let rate = (neutral + positive * 2) / 2 / total;

        if(round) {
            return Math.round(rate * 100);
        }

        return rate;
    }

    return 0;
};

export const quadKeysEncode = (quadKeys:string[]):string => {
    if(quadKeys.length === 0) {
        return "";
    }

    if(quadKeys.length === 1) {
        return quadKeys[0];
    }

    let base:string = quadKeys.find(() => true) || "";

    quadKeys.forEach(quadKey => {
        while(base && base.length > 0 && quadKey.indexOf(base) !== 0) {
            if(quadKey.indexOf(base) !== 0) {
                base = base.slice(0, base.length - 1);
            }
        }
    });

    return base + "(" + quadKeys.map((quadKey) => {
        return quadKey.slice(base.length);
    }).join("/") + ")";
};

export const quadKeysDecode = (hash:string):string[] => {
    let [base, child] = new RegExp("([0-3]+)(?:\\((.*)\\))?").exec(hash) || [];

    return child ? child.split("/").map((child) => {
        return base + child;
    }) : [base];
};

export const parseQueryBounds = (query:string):IBounds|undefined => {
    const {
        north,
        east,
        south,
        west
    } = QueryString.parse(query);

    if(
        typeof north === "number" &&
        typeof east === "number" &&
        typeof south === "number" &&
        typeof west === 'number'
      ) {
        return new Bounds({
            north,
            east,
            south,
            west
        }).toOBJECT();
    }

    return undefined;
};

export const parseQuadKeys = (query:string):string[] => {
    const bounds = parseQueryBounds(query);

    if(bounds) {
        return QuadTile.fromBounds(bounds, 22).map((quadTile:QuadTile) => {
            return quadTile.getQuadKey();
        });
    }

    const {
        q,
        quadKey,
        quadKeys = []
    } = QueryString.parse(query, {
        parseNumbers: false
    }) as {
        q?:string;
        quadKey?:string;
        quadKeys?:string[];
    };

    return [
        ...(q ? quadKeysDecode(q) : []),
        ...(quadKey ? [quadKey] : []),
        ...quadKeys
    ].sort();
};
