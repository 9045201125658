import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { initialize, change, reset } from "redux-form";
import { useParams } from "react-router-dom";
import * as lodash from "lodash";
import { Grid, CircularProgress } from "@material-ui/core";

import { PageTitle } from "src/views/blocks";
import { useShowMessages } from "src/hooks";
import store from "src/store";
import {STATUS_SUCCESS} from "src/makes/Model";


const EntityEdit = (props) => {
    const {
        name,
        id,
        form: Form,
        fields,
        values,
        showTitle = true,
        titleHeading,
        titleDescription,
        breadcrumbs,
        listPath,
        ...rest
    } = props;

    const history = useHistory();
    const params = useParams();
    const showMessages = useShowMessages();

    const [load, setLoad] = useState(true);
    const entity = props.byId(name, id);

    useEffect(() => {
        setLoad(true);

        props.fetch(name, id, params).then(res => {
            showMessages(res);

            if(res.status === STATUS_SUCCESS) {
                setLoad(false);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, id]);

    const submitHandle = async (data) => {
        const entity = {};

        for(let fieldName in fields) {
            let value = lodash.get(data, fieldName, undefined);

            if(typeof value !== 'undefined') {
                lodash.set(entity, fieldName, value);
            }
        }

        let res = await props.update(name, id, entity);

        showMessages(res);

        if(res && res.status === STATUS_SUCCESS) {
            props.initialize(props.byId(name, id));
            history.push(`${listPath}`);
        }
    };

    if(load) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <Grid item className="p-5">
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    return (
        <Fragment>
            {showTitle && (
              <PageTitle
                titleHeading={titleHeading}
                titleDescription={titleDescription}
                breadcrumbs={breadcrumbs} />
            )}

            <Form {...rest}
              form={name}
              initialValues={entity}
              data={values}
              onSubmit={submitHandle} />
        </Fragment>
    );
};

EntityEdit.propTypes = {
    form: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.any.isRequired,
    showTitle: PropTypes.bool,
    titleHeading: PropTypes.string,
    titleDescription: PropTypes.string,
    breadcrumbs: PropTypes.array,
    listPath: PropTypes.string
};


const putState = (state, props) => {
    const {
        values = {},
        registeredFields = {}
    } = state.form[props.name] || {};

    return {
        byId: store.orm.getters.byId,
        values: values,
        fields: registeredFields
    };
};

const putActions = (dispatch, props) => {
    return {
        search: store.orm.search,
        fetch: store.orm.fetch,
        update: store.orm.update,
        setValue: (field, value) => {
            return dispatch(change(props.name, field, value));
        },
        initialize: data => {
            return dispatch(initialize(props.name, data));
        },
        reset: () => {
            return dispatch(reset(props.name));
        }
    };
};

export default connect(putState, putActions)(EntityEdit);