import {IModule} from "kpdux";

/* eslint-disable */


export default {
    getters: {
        token(state, getters, rootState, rootGetters) {
            return rootGetters.auth.token;
        },
        headers(state, getters, rootState, rootGetters) {
            return rootGetters.auth.authHeaders;
        },
    },
    actions: {
        async getList(type: string, query: any = {}) {
            let accessType = "dashboard";

            const res = await this.dispatch('api/get', `/api/${accessType}/${type}`, {
            headers: this.getters.headers,
            data: query,
          }).catch((err:any) => {
            console.error('data.search(', type, query, ') -> ', err);

            return null;
          });

            let data;

            if (res) {
              switch (type) {
                case 'city-center':
                  data = await this._getListCityCenter(query, res);
                  break;

                case 'tile':
                  data = await this._getListTile(query, res);
                  break;

                case 'tile-analytic':
                  data = await this._getListTileAnalytic(query, res);
                  break;

                case 'tile-parameter':
                  data = await this._getListTileParameter(query, res);
                  break;

                case 'tile-comment':
                  data = await this._getListTileComment(query, res);
                  break;

                case 'suggest':
                  data = await this._getListSuggest(query, res);
                  break;

                case 'suggest-analytic':
                  data = await this._getListSuggestAnalytic(query, res);
                  break;

                case 'suggest-comment':
                  data = await this._getListSuggestComment(query, res);
                  break;

                case 'demand-vote':
                  data = await this._getListDemandVote(query, res);
                  break;

                default:
                  break;
              }
            }

            if (data) {
              return data;
            }

            return {
              message: 'Network error',
              ...(res || {}),
              status: 'ERROR',
              total: 0,
              items: [],
            };
        },
        async _getListCityCenter(query: any = {}, res: any) {
          if (Array.isArray(res.response)) {
            const { response: items } = res;

            return {
              status: 'OK',
              message: '',
              total: items.length,
              items: items,
            };
          }

          return null;
        },
        async _getListTile(query: any = {}, res: any) {
          if (res) {
            const {
              response: { count, data, summary },
            } = res;

            if (typeof count === 'number' && Array.isArray(data)) {
              return {
                status: 'OK',
                total: count,
                items: data,
                summary: summary,
              };
            }
          }

          return null;
        },
        async _getListTileAnalytic(query: any = {}, res: any) {
          if (res) {
            const {
              response: { count, data, summary },
            } = res;

            if (typeof count === 'number' && Array.isArray(data)) {
              return {
                status: 'OK',
                total: count,
                items: data,
                summary: summary,
              };
            }
          }

          return null;
        },
        async _getListTileParameter(query: any = {}, res: any) {
          const { count: total = null, data: items = null } = res || {};

          if (typeof total === 'number' && Array.isArray(items)) {
            return {
              status: 'OK',
              message: '',
              total: total,
              items: items,
            };
          }

          return null;
        },
        async _getListTileComment(query: any = {}, res: any) {
          const { count, data } = res;

          if (typeof count === 'number' && Array.isArray(data)) {
            return {
              status: 'OK',
              message: '',
              total: count,
              items: data,
            };
          } else if (typeof count === 'number' && typeof data === 'object') {
            return {
              status: 'OK',
              message: '',
              total: count,
              items: Object.values(data),
            };
          }

          return null;
        },
        async _getListSuggest(query: any = {}, res: any) {
          const { response: { count = null, data = null } = {} } = res || {};

          if (typeof count === 'number' && Array.isArray(data)) {
            return {
              status: 'OK',
              total: count,
              items: data,
            };
          } else if (typeof count === 'number' && typeof data === 'object') {
            return {
              status: 'OK',
              total: count,
              items: Object.values(data),
            };
          }

          return null;
        },
        async _getListSuggestAnalytic(query:any = {}, res:any) {
          const { response: { count = null, data = null } = {} } = res || {};

          if (typeof count === 'number' && Array.isArray(data)) {
            return {
              status: 'OK',
              total: count,
              items: data,
            };
          } else if (typeof count === 'number' && typeof data === 'object') {
            return {
              status: 'OK',
              total: count,
              items: Object.values(data),
            };
          }

          return null;
        },
        async _getListSuggestComment(query: any = {}, res: any) {
          if (typeof res.count === 'number' && res.data) {
            if (Array.isArray(res.data)) {
              return {
                status: 'OK',
                message: '',
                total: res.count,
                items: res.data,
              };
            } else {
              return {
                status: 'OK',
                message: '',
                total: res.count,
                items: Object.values(res.data),
              };
            }
          }

          return null;
        },
        async _getListDemandVote(query:any = {}, res:any) {
          if (res) {
            const { response: { count: total = null, data: items = null } = {} } = res;

            if (typeof total === 'number' && Array.isArray(items)) {
              return {
                status: 'OK',
                message: '',
                total: total,
                items: items.map((vote:any) => {
                  const { id, quadKey } = vote;

                  return {
                    id: id || quadKey,
                    ...vote,
                  };
                }),
              };
            }
          }

          return null;
        }
    }
} as IModule<any>;