const buildQuery = (query:any = {}) => {
    const params = new URLSearchParams();

    const lowTypes = ["string", "number", "boolean", "symbol", "undefined"];
    const deep = (data:any, key = "") => {
        if(typeof data === "object") {
            // eslint-disable-next-line
            for(let i in data) {
                deep(data[i], key ? `${key}[${i}]` : i);
            }
        }
        else if(lowTypes.includes(typeof data)) {
            switch(typeof data) {
                case "undefined":
                    break;

                case "string":
                    if(data !== "") {
                        params.set(key, data);
                    }
                    break;

                default:
                    params.set(key, data);
                    break;
            }
        }
    };

    deep(query);

    return decodeURI(params.toString());
};


export {buildQuery};
