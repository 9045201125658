import React from "react";
import PropTypes from "prop-types";
import {TableCell} from "@material-ui/core";

import "./index.scss";


const GridTableCell = (props) => {
    const {
        header: {
            name,
            render,
            width,
            align = "left"
        } = {},
        record
    } = props;

    return (
        <TableCell
          className="GridTableCellComponent"
          width={width}
          align={align}>
            {render ? (
                render(record)
            ) : (
                record[name]
            )}
        </TableCell>
    );
};

GridTableCell.propTypes = {
  header: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};


export default GridTableCell;