import React, {useState, CSSProperties} from "react";


type Props = {
  file:File;
  style?:CSSProperties;
};

export const ConvertedFileToBase64:React.FC<Props> = props => {
  const [base64, setBase64] = useState("");
  const {file, style} = props;

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    setBase64(reader.result as string);
  };

  return (<img src={base64} alt={file.name} style={style} />);
};
