import React from "react";
import PropTypes from "prop-types";
import {
    reduxForm,
    Form as ReduxForm
} from "redux-form";
import {
    Grid,
    Card,
    CardContent,
    Button
} from "@material-ui/core";

import {isRequired} from "src/tools";
import {InputField} from "src/views/rf-fields";

import {SuggestCategory} from "src/store/models/SuggestCategory";


const Form = (props) => {
    const {
        submitText,
        submitting,
        handleSubmit
    } = props;

    return (
        <ReduxForm noValidate={true} onSubmit={handleSubmit}>
            <Card>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <InputField
                              validate={isRequired}
                              label="Label (en)"
                              name="labels[en]" />
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                              validate={isRequired}
                              label="Label (da)"
                              name="labels[da]" />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-start" style={{ marginTop: 20 }}>
                        <Button
                          type="submit"
                          className="my-2"
                          color="primary"
                          variant="contained"
                          size="large"
                          disabled={submitting}>
                            {submitText || 'Submit'}
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </ReduxForm>
    );
};

Form.propTypes = {
    form: PropTypes.string.isRequired,
    submitText: PropTypes.string,
    onSubmit: PropTypes.func
};


export default reduxForm({
    form: SuggestCategory.modelName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: false
})(Form);