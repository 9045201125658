import store, {State} from "src/store";

import {useSelector} from "./useSelector";


const useModule = <Name extends keyof State>(name:Name) => {
    return useSelector((state:State) => {
        return Object.keys(store[name]._data.getters).reduce((getters:any, key:string) => {
            Object.defineProperty(getters, key, {
                get: () => store[name][key](
                    state[name],
                    store[name].getters,
                    state,
                    store[name].rootGetters
                )
            });

            return getters;
        }, {});
    });
};


export {useModule};