import React from "react";
import {
    useLocation,
    Link
} from "react-router-dom";
import {
    makeStyles,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";

import * as resources from "src/views/resources";


const DashboardSidebar:React.FC = () => {
    const classes = useStyles();

    const location = useLocation();

    return (
        <div className="dashboard-sidebar">
            <List
              className="dashboard-sidebar__list"
              classes={{
                root: classes.listRoot
              }}>
                {Object.values(resources).map((item, index) => {
                    const {
                        name,
                        basePath = `/${name}`,
                        // @ts-ignore
                        icon: Icon
                    } = item;

                    return (
                        <ListItem key={index}
                          component={Link}
                          button
                          classes={{
                            root: classes.listItemRoot,
                            selected: classes.listItemSelected
                          }}
                          selected={location.pathname.indexOf(basePath) === 0}
                          to={basePath}>
                            {Icon && (
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                            )}

                            <ListItemText
                              className="dashboard-sidebar__title"
                              primary={item.title} />
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};


const useStyles = makeStyles(() => {
    return {
        listRoot: {
            padding: 0,
        },
        listItemRoot: {},
        listItemSelected: {}
    };
});

export default DashboardSidebar;
