import React, {useState} from "react";
import {
    Grid,
    ListItem,
    Typography
} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {Dialog} from "src/views/blocks";
import {useGridTableRowContext} from "src/views/blocks/GridTable/context";
import {useShowMessages} from "src/hooks";
import {useListContext} from "src/views/core";

import {RemoveForm} from "../../forms";

import store from "src/store";
import {SuggestCategory} from "src/store/models/SuggestCategory";


const ActionsRemove = () => {
    const showMessages = useShowMessages();
    const {record} = useGridTableRowContext();
    const listContext = useListContext();

    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (data:any) => {
        // @ts-ignore
        const res = await store.orm.remove(SuggestCategory.modelName, record.id, data);

        showMessages(res);

        await listContext.reload();

        setOpen(false);
    }

    return (
        <Dialog
          fullWidth
          open={isOpen}
          activator={(props) => {
            return (
                <ListItem {...props}
                  button>
                    <div className="nav-link-icon opacity-6">
                        <FontAwesomeIcon
                          className="text-danger"
                          icon={["fas", "times"]} />
                    </div>

                    <span className="text-danger ml-4">Delete</span>
                </ListItem>
            );
          }}
          onOpen={handleOpen}
          onClose={handleClose}>
            <Grid container
              className="pt-4"
              justifyContent="center">
                <Grid item>
                    <Typography
                      variant="h4"
                      color="error">
                        Are you sure you want to delete this entry?
                    </Typography>
                </Grid>
            </Grid>

            <Grid container
              justifyContent="center">
                <Grid item>
                    <Typography
                      variant="subtitle2">
                        You cannot undo this operation.
                    </Typography>
                </Grid>
            </Grid>

            <RemoveForm
              // @ts-ignore
              onCancel={handleClose}
              onSubmit={handleSubmit} />
        </Dialog>
    );
}


export default ActionsRemove;