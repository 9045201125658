import React from "react";
import {Switch, Route} from "react-router-dom";

import ActionEdit from "../ActionEdit";
import CreateButton from "../CreateButton";
import ResourceEdit from "../ResourceEdit";
import {ResourceProvider} from "./context";

// import store from "src/store";


type Props = {
    name:string;
    title?:string;
    list?:any;
    create?:any;
    edit?:any;
    form?:any;
    basePath?:string;
    listPath?:string;
    createPath?:string;
    editPath?:string;
};

const Resource:React.FC<Props> = (props:Props) => {
    const {
        name,
        title,
        list: List,
        create: Create,
        edit: Edit,
        form: Form,
        basePath = `/${name}`,
        listPath = basePath,
        createPath = `${basePath}/create`,
        editPath = `${basePath}/:id`,
    } = props;

    const hasList = Boolean(List);
    const hasCreate = Boolean(Create);
    const hasEdit = Boolean(Edit);

    return (
        <ResourceProvider
          value={{
            name,
            listPath,
            createPath,
            editPath,
            hasList,
            hasCreate,
            hasEdit
          }}>
            <Switch>
                {List ? (
                    <Route exact path={listPath}>
                        <List
                          name={name}
                          titleHeading={title || name}
                          titleDescription={""}
                          breadcrumbs={[
                            {
                              title: title || name,
                              to: listPath,
                            },
                          ]}
                          rowActions={[...(hasEdit ? [<ActionEdit />] : [])]}
                          bottomActions={[...(hasCreate ? [<CreateButton />] : [])]} />
                    </Route>
                ) : null}

                {Create ? (
                    <Route path={createPath}>
                        <Create
                          form={Form}
                          name={name}
                          titleHeading={title || name}
                          titleDescription={""}
                          breadcrumbs={[
                            {
                              title: title || name,
                              to: listPath,
                            },
                            {
                              title: "Create",
                              to: createPath,
                            },
                          ]}
                          redirectTo={editPath}
                        />
                    </Route>
                ) : null}

                {Edit ? (
                    <Route
                      path={editPath}
                      render={(props:any) => {
                        const {
                            match: {
                                params: {
                                    id
                                }
                            }
                        } = props;

                        return (
                            <ResourceEdit
                              component={Edit}
                              // @ts-ignore
                              form={Form}
                              name={name}
                              listPath={listPath}
                              id={id}
                              titleHeading={title || name}
                              titleDescription={""}
                              breadcrumbs={[
                                {
                                    title: title || name,
                                    to: listPath
                                },
                                {
                                    title: id,
                                    to: editPath.replace(":id", id)
                                }
                              ]} />
                        );
                    }}/>
                ) : null}
            </Switch>
        </ResourceProvider>
    );
};


export type {Props as ResourceProps};

export default Resource;