import React from "react";
import {
    reduxForm,
    Form as ReduxForm
} from "redux-form";
import {
    Grid,
    Card,
    CardContent,
    Button
} from "@material-ui/core";

import {ISuggestCategory} from "src/types";

import {useORM} from "src/hooks";

import {isRequired} from "src/tools";

import {SelectField} from "src/views/rf-fields";

import {SuggestCategory} from "src/store/models/SuggestCategory";


const RemoveForm = (props:any) => {
    const {
        submitting,
        handleSubmit,
        onCancel
    } = props;

    const orm = useORM();

    const categories = orm.filter(SuggestCategory.modelName);

    const handleCancel = () => {
        onCancel && onCancel();
    };

    return (
        <ReduxForm onSubmit={handleSubmit}>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <SelectField
                              validate={[isRequired]}
                              options={categories.map((category:ISuggestCategory) => {
                                return {
                                    title: category.label,
                                    value: category.id
                                };
                              })}
                              label="Category"
                              name="recipient_id" />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card className="pt-3">
                <CardContent>
                    <Grid container
                      justifyContent="center"
                      spacing={2}>
                        <Grid item>
                            <Button
                              type="button"
                              variant="contained"
                              size="large"
                              onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                              type="submit"
                              color="secondary"
                              variant="contained"
                              size="large"
                              disabled={submitting}>
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </ReduxForm>
    );
};


export default reduxForm({
    form: SuggestCategory.modelName
})(RemoveForm);