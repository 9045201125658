import React from "react";
import PropTypes from "prop-types";
import {
    reduxForm,
    Form as ReduxForm
} from "redux-form";
import {
    Grid,
    Card,
    CardContent,
    Button
} from "@material-ui/core";

import {isRequired} from "src/tools";
import {
  InputField,
  FileField,
  CheckboxField
} from "src/views/rf-fields";

import {TileParameter} from "src/store/models/TileParameter";


const Form = props => {
    const {
        submitText,
        submitting,
        handleSubmit
    } = props;

    return (
        <ReduxForm noValidate={true} onSubmit={handleSubmit}>
            <Card>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <InputField
                              validate={[isRequired]}
                              type="text"
                              label="En title"
                              name="labels[en]" />
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                              validate={[isRequired]}
                              type="text"
                              label="Da title"
                              name="labels[da]" />
                        </Grid>

                        <Grid item xs={12}>
                            <CheckboxField
                              label="Is suggested"
                              name="isSuggested" />
                        </Grid>

                        <Grid item xs={12}>
                            <FileField
                              // validate={[isRequired]}
                              type="file"
                              label="Icon"
                              name="icon" />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent='flex-start' style={{ marginTop: 20 }}>
                        <Button
                          type='submit'
                          className='my-2'
                          variant='contained'
                          size='large'
                          color='primary'
                          disabled={submitting}>
                            {submitText || 'Submit'}
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </ReduxForm>
    );
};

Form.propTypes = {
  form: PropTypes.string.isRequired,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
};


export default reduxForm({
    form: TileParameter.modelName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: false
})(Form);
