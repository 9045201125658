import React, {
    useState,
    useEffect,
    Fragment
} from "react";
import PropTypes from "prop-types";
import {
    Dialog
} from "@material-ui/core";


const DialogWrapper = (props) => {
    const {
        activator,
        open = false,
        children,
        onOpen,
        onClose,
        ...rest
    } = props;

    const [isOpen, setOpen] = useState(open);

    useEffect(() => {
        if(open) {
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    }, [open]);

    const openHandle = () => {
        setOpen(true);

        if(onOpen) {
            onOpen();
        }
    };

    const closeHandle = () => {
        setOpen(false);

        if(onClose) {
            onClose();
        }
    };

    return (
        <Fragment>
            {activator && activator({
                onClick: openHandle
            })}

            <Dialog {...rest}
              open={isOpen}
              onClose={closeHandle}>
                {children}
            </Dialog>
        </Fragment>
    );
};

DialogWrapper.propTypes = {
    children: PropTypes.node,
    fullWidth: PropTypes.bool,
    activator: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
};


export default DialogWrapper;