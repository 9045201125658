import {Model} from "src/makes/Model";


export interface ISuggestComment {
    id:string;
    user:any;
    value:number;
    likes:number;
    content:string;
    images:any;
    date:string;
}


class SuggestComment extends Model<ISuggestComment> {
    static modelName:string = "suggest-comment";
}


export default SuggestComment;
