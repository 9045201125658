import React, {useCallback, useEffect, useState} from "react";
import {Grid, TextField, Tooltip} from "@material-ui/core";
import * as fns from "date-fns";
import "date-fns";
import {EntityList} from "src/views/core";
import {InputAuto, Select} from "src/views/inputs";

import store, {IRawData} from "src/store";
import {IUser} from "src/store/models/User";
import {useORM} from "src/hooks";

import {
    DATE_FORMAT,
    TIME_FORMAT
} from "src/env";

import "./styles.scss";


const List = (props:any) => {
    const [type, setType] = useState("");
    const [searchEmail, setSearchEmail] = useState("");
    const [selectedUserId, setSelectedUserId] = useState("");
    const orm = useORM();
    const [users, setUsers] = useState<IUser[]>([]);
    const user = orm.byId("users", selectedUserId);
    const [selectedDateFrom, setSelectedDateFrom] = React.useState(null);
    const [selectedDateTo, setSelectedDateTo] = React.useState(null);

    const handleDateFromChange = useCallback((event: any) => {
        setSelectedDateFrom(event.target.value);
    },[]);

    const handleDateToChange = useCallback((event: any) => {
        setSelectedDateTo(event.target.value);
    },[]);

    useEffect(() => {
        const timeout = setTimeout(async () => {
            if(searchEmail && searchEmail.length > 2) {
                await store.orm.search("users", {
                    email: searchEmail
                }).then((users: any) => {
                    setUsers(users.items)
                });
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, [searchEmail]);

    const onInputChange = useCallback((event, newValue, reason) => setSearchEmail(newValue),[])
    const onChange = useCallback((event, newUser, reason) => setSelectedUserId(newUser?.value),[])

    return (
        <EntityList {...props}
          pagination
          isCheckboxActions
          headActions={[
            <Grid container direction="row" spacing={3}>
                <Grid item>
                    <Select
                        options={[
                            {
                                title: "All",
                                value: ""
                            },
                            {
                                title: "vote_posted",
                                value: "vote_posted"
                            },
                            {
                                title: "suggestion_posted",
                                value: "suggestion_posted"
                            }
                        ]}
                        label="Type"
                        value={type}
                        // @ts-ignore
                        onChange={setType}
                        className="select"
                    />
                </Grid>
                <Grid item>
                    <InputAuto options={users.map((user) => {
                        return {
                            title: user.email,
                            value: user.id
                        };
                    })}
                               value={user?.id}
                               label="Email"
                        // @ts-ignore
                               onInputChange={onInputChange}
                               onChange={onChange}
                               className="select"
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="date"
                        label="Date from"
                        type="date"
                        defaultValue=""
                        value={selectedDateFrom}
                        // @ts-ignore
                        onChange={handleDateFromChange}
                        className="date-picker"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="date"
                        label="Date to"
                        type="date"
                        defaultValue=""
                        value={selectedDateTo}
                        // @ts-ignore
                        onChange={handleDateToChange}
                        className="date-picker"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
          ]}
          filter={{
            type,
            users: [selectedUserId],
            date_from: selectedDateFrom,
            date_to: selectedDateTo
          }}
          headers={[
              {
                  name: "id",
                  title: "ID"
              },
              {
                  name: "type",
                  title: "Type"
              },
              {
                  name: "mail",
                  title: "Email",
              },
              {
                  name: "data",
                  title: "Data",
                  render(record: IRawData) {
                      const {
                          data
                      } = record;

                      return (
                          <React.Fragment>
                              {JSON.stringify(data, null, 4)
                                  .replaceAll("\"", "")
                                  .replaceAll("[", "")
                                  .replaceAll("]", "")
                                  .replaceAll("{", "")
                                  .replaceAll("}", "")}
                          </React.Fragment>
                      );
                  }
              },
              {
                  name: "comments",
                  title: "Comments",
                  render(record: IRawData) {
                      const {
                          comments = {}
                      } = record;

                      return (
                          <React.Fragment>
                              {JSON.stringify(comments || {}, null, 4)
                                  .replaceAll("\"", "")
                                  .replaceAll("[", "")
                                  .replaceAll("]", "")
                                  .replaceAll("{", "")
                                  .replaceAll("}", "")}
                          </React.Fragment>
                      );
                  }
              },
              {
                  name: "time",
                  title: "Time",
                  width: 150,
                  align: "right",
                  render(record: any) {
                      const {time} = record;

                      const date = fns.parseISO(time);

                      return (
                          <Tooltip title={time} aria-label="time">
                              <div>
                                  {fns.isValid(date) && (
                                      <React.Fragment>
                                          <div>
                                              {fns.format(date, DATE_FORMAT)}
                                          </div>

                                          <div>
                                              {fns.format(date, TIME_FORMAT)}
                                          </div>
                                      </React.Fragment>
                                  )}

                                  {!fns.isValid(date) && (
                                      <React.Fragment>
                                          {time}
                                      </React.Fragment>
                                  )}
                              </div>
                          </Tooltip>
                      );
                  }
              }
          ]} />
    );
};


export default List;
