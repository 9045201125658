import React from 'react';
import { Field, BaseFieldProps } from 'redux-form';

import InputWrapper from '../InputWrapper';

interface Props extends BaseFieldProps {
  type?:string;
  required?:boolean;
  fullWidth?:boolean;
  label?:string;
}

interface CProps extends Props {}

class InputField extends React.Component<CProps, any> {
  render() {
    return <Field component={InputWrapper} {...this.props} />;
  }
}

export default InputField;
