import ReactDom from "react-dom";
import PropTypes from "prop-types";

import InputMask from "react-input-mask";


class InputMaskReload extends InputMask {
    constructor(props) {
        super(props);

        this.getInputDOMNode = () => {
            if(!this.mounted) {
                return null;
            }

            let input = ReactDom.findDOMNode(this);
            let isDOMNode = typeof window !== "undefined" && input instanceof window.Element;

            if(input && !isDOMNode) {
                return null;
            }

            if(input.nodeName !== "INPUT") {
                input = input.querySelector('input[type="text"]');
            }

            if(!input) {
                throw new Error("react-input-mask: inputComponent doesn't contain input node");
            }

            return input;
        };
    }
}

InputMaskReload.propTypes = {
    mask: PropTypes.string.isRequired,
    formatChars: PropTypes.object,
    value: PropTypes.string,
    maskChar: PropTypes.string,
    onChange: PropTypes.func
};


export default InputMaskReload;