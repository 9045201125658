import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, TableRow, TableCell } from '@material-ui/core';

import { VerticalMenu } from '../../../index';

import GridTableCell from '../GridTableCell';

import { GridTableRowProvider } from '../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@material-ui/core/Checkbox'

const GridTableRow = props => {
  const { headers = [], selected = [], rowActions = [], isCheckboxActions = false, record = {}, onClick } = props;

  const [isOpen, setOpen] = useState(false);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <GridTableRowProvider
      value={{
        record: record,
        closeMenu: () => setOpen(false),
      }}
    >
      <TableRow hover={Boolean(onClick)} onClick={onClick && ((event) => onClick(event, record.id))}>
        {isCheckboxActions && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isSelected(record.id)}
              inputProps={{ 'aria-labelledby': record.id }}
            />
          </TableCell>
        )}
        {headers.map((header, index) => {
          return <GridTableCell key={index} header={header} record={record} />;
        })}

        {rowActions.length ? (
          <TableCell align='right' width={100}>
            <VerticalMenu
              activator={props => {
                return (
                  <IconButton {...props} size='small'>
                    <FontAwesomeIcon icon={['fas', 'ellipsis-h']} />
                  </IconButton>
                );
              }}
              open={isOpen}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
            >
              {rowActions.map((rowAction, index) => {
                return React.cloneElement(rowAction, {
                  ...rowAction.props,
                  key: index,
                });
              })}
            </VerticalMenu>
          </TableCell>
        ) : null}
      </TableRow>
    </GridTableRowProvider>
  );
};

GridTableRow.propTypes = {
  record: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired,
  isCheckboxActions: PropTypes.bool,
  headers: PropTypes.array,
  rowActions: PropTypes.array,
  onClick: PropTypes.func,
};

export default GridTableRow;
