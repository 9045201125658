import {useEffect} from "react";

import {useSelector} from "./useSelector";
import {useShowMessages} from "./useShowMessages";

import store from "src/store";
import {STATUS_SUCCESS, STATUS_ERROR} from "src/makes/Model";


const useDebug = () => {
    const showMessages = useShowMessages();
    const {mode} = useSelector((state) => state.ui);

    useEffect(() => {
        if(mode === "development") {
            (window as any).store = store;

            return () => {
                delete (window as any).store;
            };
        }
    }, [mode]);

    useEffect(() => {
        const handleKeyDown = (e:any) => {
            if(e.altKey && e.code === "KeyC") {
                e.preventDefault();

                if(mode === "production") {
                    store.ui.setMode("development");

                    showMessages({
                        status: STATUS_ERROR,
                        message: "Mode: development"
                    });
                }
                else {
                    store.ui.setMode("production");

                    showMessages({
                        status: STATUS_SUCCESS,
                        message: "Mode: production"
                    });
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [mode]);
};


export {useDebug};