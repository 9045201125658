import React from "react";
import {compose} from "redux";
import {
    withRouter,
    RouteComponentProps
} from "react-router";
import {
    Tab,
    TabProps,
    createStyles,
    withStyles
} from "@material-ui/core";

import {getRestProps} from "../../../tools";


interface Props extends TabProps {
    exact?:boolean;
    path?:string;
    to?:string;
}

interface CProps extends Props, RouteComponentProps {}


const PageTab:React.FC<CProps> = (props:CProps) => {
    const rest = getRestProps(props, [
        "icon",
        "label",
        "fullWidth",
        "indicator",
        "selected",
        "selectionFollowsFocus",
        "onChange",
        "textColor",
        "value"
    ]);

    const {
        to,
        history
    } = props;

    return (
        <Tab
          onClick={() => to && history.push(to)}
          {...rest} />
    );
};


const styles = () => createStyles({});

export default compose<React.ComponentType<Props>>(
    withStyles(styles),
    withRouter
)(PageTab);