import {Model} from "src/makes/Model";

/* eslint-disable */


export interface IDemandVote {
  id: string;
  quadKey: string;
  categories: {
    category_id: string;
    demand: number;
    score: number;
  }[];
  location: {
    lat: number;
    lon: number;
  };
}


class DemandVote extends Model<IDemandVote> {
  static modelName = 'demand-vote';
  static filters = {
    score: (vote: IDemandVote, filter: any) => {
      const {
        categoryId = '',
        score = 0
      } = filter || {};

      return !!vote.categories.find((category: any) => {
        return (
          category.category_id === categoryId &&
          category.score <= score
        );
      });
    },
    categoryId: (vote: IDemandVote, categoryId: string) => {
      return !!vote.categories.find((category: any) => {
        return category.category_id === categoryId;
      });
    },
    quadKey: (vote: IDemandVote, quadKey: string | string[]) => {
      if (Array.isArray(quadKey)) {
        return quadKey.reduce((includes: boolean, quadKey: string) => {
          return includes || vote.quadKey.indexOf(quadKey) === 0;
        }, false);
      } else {
        return vote.quadKey.indexOf(quadKey) === 0;
      }
    }
  };
}


export default DemandVote;
