import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetList,
    IResponseRemoveMany
} from "src/makes/Model";


type IRawData = {
    id:string;
    type:string;
    data:any;
    comments:any;
    time:string;
};

class RawData extends Model<IRawData> {
    static modelName:string = "raw-data";
    static accessType:string = "admin";

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<any> {
        const {
            type,
            ...otherQuery
        } = query;

        const res = await this.getFilteredList({
            ...type ? {
                types: [type]
            } : {},
            ...otherQuery,
            ...count > 0 || page > 0 ? {
                count,
                page: page + 1
            } : {}
        });

        if(Array.isArray(res.data)) {
            const {
                meta: {
                    total
                },
                data: items
            } = res;

            return {
                status: STATUS_SUCCESS,
                total,
                items: items//.map(RawData.prepare)
            };
        }

        const {
            response: {
                message = ""
            } = {}
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }

    async removeManyRows(data:[]):IResponseRemoveMany<any> {
        const res = await this._removeMany(data)

        if(res.httpCode === 200) {
            return {
                status: STATUS_SUCCESS,
                message: "Rows deleted"
            };
        }

        const {
            response: {
                message
            }
        } = res;

        return {
            status: STATUS_ERROR,
            message
        };
    }


    static prepare(item:any):IRawData {
        const {
            data: {
                comments = {},
                ...otherData
            },
            ...other
        } = item;

        return {
            ...other,
            comments,
            data: otherData
        };
    }
}


export type {IRawData};

export {RawData};
