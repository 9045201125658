import React from "react";
// import PropTypes from "prop-types";

import {
    InputHidden
} from "../../inputs";


const InputHiddenWrapper = () => {
    return (
        <InputHidden />
    );
};

InputHiddenWrapper.propTypes = {};


export default InputHiddenWrapper;