import React from "react";
import PropTypes from "prop-types";

import {
    Select
} from "../../inputs";


const SelectWrapper = (props) => {
    const {
        meta,
        input,
        options,
        disabled = false,
        ...rest
    } = props;

    return (
        <Select
          {...rest}
          {...input}
          disabled={disabled}
          error={meta.touched ? meta.error : ""}
          options={options} />
    );
};

SelectWrapper.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.array.isRequired
};


export default SelectWrapper;