import React, {
    useContext
} from "react";


export const GridTableRowContext = React.createContext({
    record: {},
    closeMenu: () => {}
});

export const useGridTableRowContext = () => {
    return useContext(GridTableRowContext);
};

export const GridTableRowProvider = GridTableRowContext.Provider;
export const GridTableRowConsumer = GridTableRowContext.Consumer;