import * as fns from "date-fns";

import {Model} from "src/makes/Model";
import { calcRate } from "src/tools";

/* eslint-disable */


export interface ITileAnalytic {
  id: string;
  quadKey: string;
  votesTotal: number;
  votesPositive: number;
  votesNeutral: number;
  votesNegative: number;
  votesSurroundingTotal: number;
  date: string;
}

export class TileAnalytic extends Model<ITileAnalytic> {
  static modelName: string = 'tile-analytic';
  static queryCount: string = 'count';
  static queryPage: string = 'page';

  toString(): string {
    return '';
  }

  static listSummary(tileAnalytics: ITileAnalytic[], res: any, query: any) {
    if (query && query.withSummary) {
      const { summary: { dates = {}, ...summary } = {} } = res || {};

      const minDate = Object.keys(dates)
        .sort((a, b) => {
          return a < b ? -1 : 1;
        })
        .find(() => true);

      let months = 0;

      if (minDate) {
        let date = fns.parse(minDate, 'yyyy-MM', new Date());

        if (fns.isValid(minDate)) {
          months = fns.differenceInCalendarMonths(new Date(), date) + 1;
        }
      }

      return {
        totals: Array(Math.max(months, 12))
          .fill(0)
          .map((ignore, index: number, arr: any[]) => {
            let date = fns.subMonths(new Date(), arr.length - 1 - index);

            return fns.format(date, 'yyyy-MM');
          })
          .map((date: string) => {
            const { [date]: { votes = 0 } = {} } = dates;

            const {
              votesPositive = 0,
              votesNeutral = 0,
              votesNegative = 0,
              // ,
              // votesSurroundingTotal = 0
            } = tileAnalytics.reduce(
              (summary:any, tileAnalytic:ITileAnalytic) => {
                if (date === tileAnalytic.date) {
                  summary.votesPositive += tileAnalytic.votesPositive;
                  summary.votesNeutral += tileAnalytic.votesNeutral;
                  summary.votesNegative += tileAnalytic.votesNegative;
                  // summary.votesSurroundingTotal += tileAnalytic.votesSurroundingTotal;
                }

                return summary;
              },
              {
                votesPositive: 0,
                votesNeutral: 0,
                votesNegative: 0,
                votesSurroundingTotal: 0,
              }
            );

            return {
              date: date,
              votes: votes,
              rate: calcRate(votesPositive, votesNeutral, votesNegative),
            };
          }),
        ...summary,
      };
    }

    return null;
  }
}

export default TileAnalytic;
