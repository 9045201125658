import {IModule} from "kpdux";


type State = {
    mode:"production"|"development"|"test";
    isDrawerOpen: boolean;
};

const ui:IModule<State> = {
    state: {
        mode: process.env.NODE_ENV,
        isDrawerOpen: false,
    },
    getters: {
        isDev(state:State) {
            return state.mode === "development";
        }
    },
    mutations: {
        setMode(state:State, mode:State["mode"]) {
            state.mode = mode;
        },
        setDrawerOpen(state:State, isDrawerOpen: boolean) {
            state.isDrawerOpen = isDrawerOpen;
        }
    }
};


export type {State as UIState};

export {ui};
