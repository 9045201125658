import React from "react";
import PropTypes from "prop-types";
import {Divider} from "@material-ui/core";

import {
    EntityList,
    CreateButton
} from "src/views/core";

import {ActionsRemove} from "./actions";

import {SuggestCategory} from "src/store/models/SuggestCategory";


const List:React.FC = (props:any) => {
    const {
        rowActions
    } = props;

    return (
        <EntityList {...props}
          titleHeading="Suggestions"
          title="Suggest category list"
          breadcrumbs={[
            {
                title: "Suggest category",
                to: `/${SuggestCategory.modelName}`
            }
          ]}
          headers={[
            {
                name: "id",
                title: "ID",
                sortable: true,
                width: 350
            },
            {
                name: "label",
                title: "Label",
                sortable: true
            }
          ]}
          rowActions={[
            ...rowActions,
            <Divider />,
            <ActionsRemove />
          ]}
          bottomActions={[
            <CreateButton />
          ]} />
    );
};

List.propTypes = {
    name: PropTypes.string
};


export default List;